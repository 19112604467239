<template>
  <div class="footer">
    <div class="container">
      <div class="footer-top">
        <div class="footer-nav">
          <div class="footer-menu">
            <div
              class="footer-menu__column"
              v-for="(column, index) in footerMenuInColumns"
              :key="index"
            >
              <ul class="footer-menu__list">
                <li class="footer-menu__item">
                  <a
                    href="https://zmed.ru"
                    class="footer-menu__link"
                  >Вернуться на сайт</a>
                </li>
                <li
                  class="footer-menu__item"
                  v-for="(menuItem, index) in column"
                  :key="index"
                >
                  <router-link
                    :to="menuItem.url"
                    class="footer-menu__link"
                    v-text="menuItem.name"
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="footer-app">
          <a rel="nofollow" href="https://play.google.com/store/apps/details?id=com.lombardis.lombardone" class="footer-app__item m--google" target="_blank"></a>
          <a rel="nofollow" href="https://apps.apple.com/ru/app/%D0%BB%D0%BE%D0%BC%D0%B1%D0%B0%D1%80%D0%B4-1-%D0%B7%D0%B0%D0%B5%D0%BC%D1%89%D0%B8%D0%BA/id1644657978" class="footer-app__item m--store" target="_blank"></a>
        </div>
        <div class="footer__info" v-html="getSimplePage('footer')"></div>
        <div class="footer__contacts">
          <div class="footer__contacts-block">
            <ul class="social-menu">
              <li
                class="social-menu__item"
                v-for="(social, index) in socialMenu"
                :key="index"
              >
                <a
                  :href="social.url"
                  target="_blank"
                  class="social-menu__link social-menu__icon"
                  :class="[`social-menu__icon--${social.name}`]"
                ></a>
              </li>
            </ul>
            <a
              v-if="mainPhone"
              :href="$helpers.formatTel(mainPhone)"
              class="footer__phone with-icon-left with-icon-left--phone"
              v-text="mainPhone"
            ></a>
            <a
              v-if="mainEmail"
              :href="`mailto:${mainEmail}`"
              class="footer__email with-icon-left with-icon-left--mail"
              v-text="mainEmail"
            ></a>
          </div>
          <a href="https://flexites.org" target="_blank" class="footer__contacts-developer">
            сайт разработан
          </a>
        </div>
      </div>
      <!-- footer-top -->
    </div>
    <div class="container">
      <div class="footer__policy">
        <span @click="isShowRequisites = true" class="footer__policy-link"
          >Реквизиты</span
        >
        <span @click="isShowTerms = true" class="footer__policy-link"
          >Политика конфиденциальности</span
        >
      </div>

      <div class="footer__copyright">
          © 2001-<span v-show="year">{{year}}</span> ООО «Ломбард-1». Финансово-торговый холдинг «Золотые
        медведи». Все права защищены
      </div>
      <a
        href="https://flexites.org"
        class="footer__contacts-developer footer__contacts-developer--mobile"
      >
        сайт разработан
      </a>
    </div>

    <vue-final-modal
      v-model="isShowRequisites"
      classes="modal-container"
      content-class="modal-content modal-content--wide"
      @closed="isShowRequisites = false"
    >
      <div class="modal-close" @click="isShowRequisites = false"></div>

      <div class="modal-inner">
        <div v-html="getSimplePage('requisites')"></div>
      </div>
    </vue-final-modal>

    <vue-final-modal
      v-model="isShowTerms"
      classes="modal-container"
      content-class="modal-content modal-content--wide"
      @closed="isShowTerms = false"
    >
      <div class="modal-close" @click="isShowTerms = false"></div>

      <div class="modal-inner">
        <div v-html="getSimplePage('terms')"></div>
      </div>
    </vue-final-modal>
  </div>
  <!-- footer -->
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'FooterSite',
  props: {},
  data() {
    return {
      footerMenuCols: 1,
      footerMenu: [
        {
          url: '/loans',
          name: 'Мои займы'
        },
        {
          url: '/',
          name: 'Условия оплаты'
        },
        {
          url: '/help',
          name: 'Помощь'
        },
        {
          url: '/loyalty',
          name: 'Программа лояльности'
        }
      ],
      socialMenu: [
        {
          url: 'https://vk.com/zolotiemedvedy',
          name: 'vk'
        },
        {
          url: 'https://t.me/zolotiemedvedy',
          name: 'tg'
        },
        {
          url: 'https://wa.me/79234344029',
          name: 'wa'
        },
        // {
        //   url: '/',
        //   name: 'youtube'
        // }
      ],
      year: ''
    }
  },
  methods: {
    ...mapActions(useStore, ['getSimplePage']),
    printYear: function () {
        return new Date().getFullYear();
    },
  },
  computed: {
    ...mapWritableState(useStore, ['isShowTerms', 'isShowRequisites']),
    ...mapState(useStore, ['mainPhone', 'mainEmail']),
    footerMenuInColumns() {
      const columns = []
      const middle = Math.ceil(this.footerMenu.length / this.footerMenuCols)
      for (let col = 0; col < this.footerMenuCols; col++) {
        columns.push(this.footerMenu.slice(col * middle, col * middle + middle))
      }
      return columns
    },
  },
  mounted: function () {
    this.year = this.printYear();
  },
  async created() {
    if (!this.footerInfo) {
      let request = {
        method: 'get',
        params: {
          format: 'json',
          slug: 'footer'
        },
        url: rest._constructURL(config.urls.simplePages)
      }

      await rest
        ._request(request)
        .then((response) => {
          try {
            this.footerInfo = response.data[0].text
          } catch (e) {
            console.log(e)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
.footer {
  padding: 40px 0;
  background-color: #fff;

  &-top {
    display: grid;
    grid-template-columns: 190px 210px auto 250px;
    grid-column-gap: 40px;
    grid-template-areas: 'footer-nav footer-app footer-info footer-contacts';
    margin-bottom: 15px;
  }

  &-nav {
    // grid-area: footer-nav;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
  }

  &-app {
    &__item {
      display: block;
      margin-bottom: 30px;
      height: 70px;
      width: 210px;
      border-radius: 12px;
      background-color: $color-text-gold;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 180px auto;
      &.m--google {
        background-image: url('@/assets/i/icons/google.svg');
      }
      &.m--store {
        background-image: url('@/assets/i/icons/store.svg');
      }
    }
  }

  &__info {
    font-weight: 400;
    font-size: 10px;
    color: $color-text-darker;
    grid-area: footer-info;

    & p {
      margin: 0;
    }
  }

  &__contacts {
    grid-area: footer-contacts;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-developer {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      color: $color-text-gray;
      text-align: right;
      vertical-align: middle;
      text-decoration: none;

      &--mobile {
        display: none;
      }

      &::after {
        content: '';
        display: inline-block;
        height: 33px;
        width: 34px;
        margin-left: 18px;
        background-image: url('@/assets/i/icons/flexites.svg');
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
      }
    }
  }

  &__copyright {
    line-height: 22px;
    font-size: 12px;
  }

  &-menu {
    &__column {
    }

    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__item {
      margin-bottom: 16px;
    }

    &__link {
      font-size: 16px;
      font-weight: 700;
      color: $color-text-black;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 20px;

      &:hover {
        color: $color-text-gold;
      }
    }
  }

  &__policy {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;

    &-link {
      font-size: 12px;
      font-weight: 400;
      text-decoration: none;
      color: $color-text-black;
      cursor: pointer;

      &:hover {
        color: $color-text-gold;
      }
    }
  }

  &__phone,
  &__email {
    display: block;
    margin-bottom: 20px;
    line-height: 36px;
    font-weight: 500;
    font-size: 18px;
    color: $color-text-black;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $color-text-gold;
    }
  }

  &__copyright {
    text-align: center;
    margin-bottom: 20px;
  }
}

.social-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px;

  &__item {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  &__link {
    display: inline-block;
    width: 36px;
    height: 36px;
    text-decoration: none;
    border-radius: 100%;
    transition: all ease 0.5s;

    &:hover {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
    }
  }

  &__icon {
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position: 0 0;

    &--vk {
      background-image: url('@/assets/i/icons/vk.svg');
    }

    &--tg {
      background-image: url('@/assets/i/icons/tg.svg');
    }

    &--wa {
      background-image: url('@/assets/i/icons/wa.svg');
    }

    &--facebook {
      background-image: url('@/assets/i/icons/facebook.svg');
    }
    &--instagram {
      background-image: url('@/assets/i/icons/instagram.svg');
    }
    &--youtube {
      background-image: url('@/assets/i/icons/youtube.svg');
    }
  }
}

@media screen and (max-width: 1920px) {
  .footer {
    padding: 20px 0;

    &-top {
      grid-column-gap: 20px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    &-top {
      display: flex;
      gap: 10px;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__info {
      margin-bottom: 24px;
    }

    &__policy {
      display: block;
      margin-bottom: 40px;

      &-link {
        display: block;
        margin-bottom: 6px;
      }
    }

    &__contacts {
      &-developer {
        display: none;
        text-align: center;

        &--mobile {
          display: block;
        }
      }
    }
    &__copyright {
      margin-bottom: 28px;
    }
  }

  .social-menu {
    margin-bottom: 20px;
  }
}
</style>
