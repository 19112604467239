<template>
    <div>
        <vue-final-modal
        v-model="isShowNoticeModal"
        classes="modal-container"
        content-class="modal-content modal-content--wide"
        @closed="isShowNoticeModal = false"
        >
            <div class="modal-close" @click="isShowNoticeModal = false"></div>
            <div class="modal-inner">
                <div class="notice-form">
                    <div class="container">
                        <div class="content">
                        <FormKit
                            v-if="!formSubmited"
                            type="form"
                            v-model="form"
                            :classes="{
                              form: 'notice-form__form'
                            }"
                            @submit="submit"
                            :actions="false"
                            submit-label="Отправить"
                        >
                            <div class="notice-form__title" v-html="message"></div>
            
                            <div class="notice-form__subtitle">
                                Если у вас есть вопросы, напишите нам и наши менеджеры свяжутся с 
                                <br />
                                вами в ближайшее в ремя
                            </div>
            
                            
                            <div class="formkit-row">
                              <div class="formkit-input">
                                <FormKit
                                    type="text"
                                    name="name"
                                    label="Имя"
                                    :classes="{}"
                                    validation="required"
                                    placeholder="Золото"
                                    :value="username"
                                />
                              </div>
              
                              <div class="formkit-input">
                                <FormKit
                                    type="text"
                                    name="phone"
                                    label="Телефон"
                                    validation=""
                                    :classes="{}"
                                    placeholder="585"
                                />
                              </div>
              
                              <div class="formkit-input">
                                <FormKit
                                    type="email"
                                    name="email"
                                    label="E-mail"
                                    validation="required|email"
                                    :classes="{}"
                                    placeholder="585"
                                    :value="useremail"
                                />
                              </div>
                            </div>
            
                            <div class="formkit-input">
                              <FormKit
                              type="textarea"
                              name="text"
                              :classes="{
                                input: 'common-textarea mb25'
                              }"
                              rows="5"
                              validation="required"
                              />
                            </div>
            
                            <div class="formkit-row jcsb">
                              <div class="notice-form__terms">
                                  <FormKit
                                    type="checkbox"
                                    name="terms"
                                    :sections-schema="{
                                        // outer: { $el: null },
                                        // inner: { $el: null },
                                        // label: { $el: null }
                                    }"
                                    :classes="{
                                        outer: 'formkit-checkbox'
                                    }"
                                    label="Я ознакомлен и согласен с условиями политики обработки персональных данных и конфиденциальности"
                                    validation="required"
                                    validation-visibility="dirty"
                                  />
                              </div>
              
                              <FormKit
                                  type="submit"
                                  label="Отправить"
                                  :classes="{
                                    input: 'common-button common-button--gold'
                                  }"
                              >
                              </FormKit>
                            </div>
                          </FormKit
                        ><!-- form -->
                        <div v-else>
                            <h2>Сообщение отправлено!</h2>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'NoticeModal',
  components: {
  },
  props: {
    // date: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      formSubmited: false,
      form: {
        theme: 'Заполнена форма из уведомления о начале льготного периода по билету'
      },
      message: null,
    }
  },
  computed: {
    ...mapState(useStore, ['ClientID', 'username', 'useremail', 'getAllLoans']),
    ...mapWritableState(useStore, ['isShowNoticeModal', 'isShowTerms'])
  },
  methods: {
    ...mapActions(useStore, ['EmailTemplateNotice']),
    submit() {
      let body = this.EmailTemplateNotice(this.form)
      const request = {
        data: {
          theme: this.form.theme,
          body
        },
        url: rest._constructURL(config.urls.sendEmail),
      }

      const loader = this.$loading.show(this.$loaderProps)

      rest
        ._request(request)
        .then((response) => {
          this.formSubmited = true
          setTimeout(() => {
            this.isShowNoticeModal = false
          }, 4000)
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loader.hide()
        })
    },
  },
  watch: {
    getAllLoans: {
      handler() {
        if (!Array.isArray(this.getAllLoans) || this.getAllLoans.length === 0) return

        const request = {
          data: {
            ClientID: this.ClientID,
          },
          url: rest._constructURL(config.urls.getPopupNotificationData),
        }
    
        rest
          ._request(request)
          .then((response) => {
            let LoanID = response.data.LoanID
            if (LoanID) {
              let findedLoan = this.getAllLoans.find((loan) => {
                return loan.LoanID === LoanID
              })
              let now = new Date()
              let paymentDate = new Date(findedLoan.PaymentDate)
              let sellingDate = new Date(findedLoan.sellingDate)

              if (paymentDate < now && !response.data.is_shown_start_period) {
                this.message = `c <span class="common-gold">${this.$helpers.formatDate(
                    paymentDate,
                    'DD.MM.YYYY'
                  )}</span> у вас начался льготный период по билету <span class="common-gold">${findedLoan.pawnBillNumber}</span>`
                this.isShowNoticeModal = true
              } else if(sellingDate < now && !response.data.is_shown_end_period) {
                this.message = `c <span class="common-gold">${this.$helpers.formatDate(
                  sellingDate,
                  'DD.MM.YYYY'
                )}</span> у вас наложено обременение на вещь: <span class="common-gold">${findedLoan.ShortLoanDescription}</span>`
                this.isShowNoticeModal = true
              }
      
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notice-form {
  &__fieldgroup {
    margin-bottom: 40px;
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    text-transform: uppercase;
    color: $color-text-black;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    color: #4F4F4F;
    margin-bottom: 20px;
  }

  &__terms {
    display: flex;
    position: relative;
    flex-flow: column;
    margin-left: 28px;
    font-size: 12px;
    line-height: 15px;
    color: #999;

    &-link {
      text-decoration: none;
      color: $color-text-black;
      cursor: pointer;
    }
  }

  &__send {
    width: 200px;
    box-sizing: border-box;
    padding: 13px 8px;
  }
}

@media screen and (max-width: 1000px) {
  .notice-form {
    &__title {
      
    }
  }
}
</style>
