import gsap from 'gsap'

function onBeforeEnter(el) {
  el.style.opacity = 0
  el.style.height = 0
}

function onEnter(el, done) {
  // gsap.to(el, {
  //   opacity: 1,
  //   height: 'auto',
  //   delay: el.dataset.index * 0.05,
  //   onComplete: done
  // })
  // gsap.fromTo(el,{autoAlpha:1},{autoAlpha:0, duration: 0.35});
}

function onLeave(el, done) {
  // gsap.fromTo(el,{autoAlpha:1},{autoAlpha:0, duration: 0.35});
  // gsap.to(el, {
  //   opacity: 0,
  //   height: 0,
  //   delay: el.dataset.index * 0.05,
  //   onComplete: done
  // })
}

export { onBeforeEnter, onEnter, onLeave }
