<template>
    <div
        class="payment-info"
        v-if="paymentInfo && paymentInfo.sellingDate"
    >
        <div class="payment-info__item">
        <div class="payment-info__title">Ближайший платеж</div>
        <div class="payment-info__status">
            {{ paymentInfo.sellingDate }}
        </div>
        </div>
        <div class="payment-info__item">
        <div class="payment-info__title">Размер платежа</div>
        <div class="payment-info__status">
            {{ paymentInfo.fullDebt }} ₽
        </div>
        </div>
        <div class="payment-info__item flex-as-center">
        <router-link
            :to="`/loans/${paymentInfo.LoanID}`"
            class="payment-info__link common-button common-button--gold"
            @click="isShowHeaderMobile = false" 
            >Перейти к билету</router-link
        >
        <!-- <div class="payment-info__title">Ставка</div> -->
        <!-- <div class="payment-info__status">Статус - VIP</div> -->
        </div>
    </div>
    <!-- payment-info -->
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useStore } from '../stores/store'

export default {
  name: 'PaymentInfo',
  props: {},
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapState(useStore, ['paymentInfo']),
    ...mapWritableState(useStore, ['isShowHeaderMobile']),
  }
}
</script>

<style scoped lang="scss">

.payment-info {
  display: flex;
  justify-content: flex-start;
  flex-basis: min-content;
  gap: 2rem;
  line-height: normal;
  margin: 0 20px;

  &__item {
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    color: $color-text-gray;
    padding-bottom: 5px;
  }

  &__status {
    font-weight: 400;
    font-size: 18px;
    color: $color-text-black;
    white-space: nowrap;
  }

  &__link {
    padding: 8px;
    width: 180px;
    text-transform: none;
    font-size: 14px;
  }

}

@media screen and (max-width: 1520px) {
  .payment-info {
    gap: 1.2rem;
  }
}


@media screen and (max-width: 1380px) {
  .payment-info {
    &__title {
      font-size: 14px;
    }

    &__status {
      font-size: 14px;
    }
  }
}


@media screen and (max-width: 1000px) {
  .payment-info {
    display: block;
    margin: 0;
    border-bottom: 1px solid #CACACA;


    &__item {
      margin-bottom: 22px;
      &-status {
        font-weight: 500;
      }
    }
  }
}
</style>