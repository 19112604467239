<template>
  <div class="loan-list-filter-per-page__wrapper">
    <div class="filter" v-if="limit !== 4">
      <div class="filter__list">
        <div
          class="filter__item"
          v-for="(filterItem, index) in filterList"
          :class="[
            filterClass(filterItem.code),
            { active: filterCurrentStatus == filterItem.code }
          ]"
          @click="filterCurrentStatus = filterItem.code"
          :key="index"
        >
          <span class="filter__name" v-text="filterItem.name"></span>
        </div>
      </div>
    </div>

    <TransitionGroup
      name="list"
      :css="false"
      tag="div"
      class="loan-list-filter-per-page common-loan-list"
    >
      <LoanCard
        v-for="loan in resultLoans"
        :key="loan.LoanID"
        :loanCard="loan"
      ></LoanCard>
    </TransitionGroup>

    <div class="pagination" v-if="!isLoanListEmpty && pageCount > 1">
      <div class="">
        <!-- <button @click="prevPage" :disabled="pageNumber==0"> -->
        <!-- Previous -->
        <!-- </button> -->
        <ul class="pagination__list">
          <li
            class="pagination__item"
            :class="{ 'pagination__item--current': page == pageNumber + 1 }"
            v-for="(page, index) in pageCount"
            :key="index"
            @click="setPageNumber(page - 1)"
          >
            {{ page }}
          </li>
        </ul>
        <button
          @click="nextPage"
          :disabled="pageNumber >= pageCount - 1"
          class="pagination__item pagination__item--next"
        ></button>
      </div>
    </div>

    <div v-if="isLoanListEmpty">Нет билетов для просмотра</div>
    <!-- <Transition @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave"> -->
    <!-- </Transition> -->
  </div>
</template>

<script>
import LoanCard from './LoanCard.vue'
import { onBeforeEnter, onEnter, onLeave } from '../services/transition'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'LoanListFilterPerPage',
  props: {
    filter: String,
    perPage: Number,
    limit: Number
  },
  components: {
    LoanCard
  },
  data() {
    return {
      resultLoans: [],
      pageNumber: 0
    }
  },
  methods: {
    ...mapActions(useStore, ['getLoanStatus']),
    // setFilter(code) {
    //   this.filterCurrentStatus = code
    // },
    filterClass(code) {
      return `filter__item--${code}`
    },
    onBeforeEnter(el) {
      onBeforeEnter(el)
    },
    onEnter(el, done) {
      onEnter(el, done)
    },
    onLeave(el, done) {
      onLeave(el, done)
    },
    pagination() {
      if (this.limit > 0) {
        this.getFilteredLoans.splice(this.limit)
      }

      const start = this.pageNumber * this.perPage,
        end = start + this.perPage

      this.resultLoans = this.getFilteredLoans.slice(start, end)
    },
    nextPage() {
      this.pageNumber++
    },
    prevPage() {
      this.pageNumber--
    },
    setPageNumber(pageNumber) {
      this.pageNumber = pageNumber
    }
  },
  computed: {
    ...mapState(useStore, ['loanList', 'getAllLoans', 'getFilteredLoans', 'filterList', 'ClientID']),
    ...mapWritableState(useStore, [
      'filterCurrentStatus',
      'loanList',
      'paymentInfo',
      'favorites',
    ]),
    isLoanListEmpty() {
      return this.resultLoans.length == 0
    },
    pageCount() {
      let l = this.getFilteredLoans.length,
        s = this.perPage
      return Math.ceil(l / s)
    }
  },
  beforeMount() {
    if (Array.isArray(this.loanList) && this.loanList.length > 0) return
    if (!localStorage.ClientID || localStorage.ClientID == 'undefined') return

    const request = {
      data: {
        ClientID: this.ClientID,
        closed: false
      },
      url: rest._constructURL(config.urls.getClientLoans)
    }

    const loader = this.$loading.show(this.$loaderProps)

    rest
      ._request(request)
      .then((response) => {
        try {
          if (response.data['isError']) {
            console.log(response.data['errorMessage'])
          } else {
            this.loanList = response.data['Loans']
            if (this.paymentInfo === null) {
              for (let loan of this.loanList) {
                let nextDate = new Date(loan.sellingDate)
                let current

                if (
                  this.paymentInfo &&
                  typeof this.paymentInfo.sellingDate !== undefined
                )
                  current = new Date(this.paymentInfo.sellingDate)
                else current = new Date()

                // для paymentInfo ближайший билет для оплаты
                if (current.getTime() < nextDate.getTime())
                  this.paymentInfo = {
                    sellingDate: nextDate.toLocaleString('ru', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }),
                    fullDebt: loan.fullDebt,
                    LoanID: loan.LoanID
                  }
              }
            }
          }
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        loader.hide()
      })

    const request2 = {
      data: { ClientID: this.ClientID },
      url: rest._constructURL(config.urls.getFavorites)
    }

    rest
      ._request(request2)
      .then((response) => {
        try {
          for (let i in response.data)
            this.favorites.add(response.data[i].fields.LoanID)
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {})

    // filter
    this.filterCurrentStatus = this.filter
  },
  watch: {
    getFilteredLoans: {
      immediate: true,
      handler() {
        this.pagination()
      }
    },
    limit: {
      immediate: true,
      handler() {
        this.pagination()
      }
    },
    pageNumber: {
      immediate: true,
      handler() {
        this.pagination()
      }
    },
    getAllLoans: {
      handler() {
        if (!Array.isArray(this.getAllLoans) || this.getAllLoans.length === 0) return

        let notificationLoanList = []
        let now = new Date()
        for (const loan of this.getAllLoans) {
          let stauts = this.getLoanStatus(loan)
          if (stauts === 'active') {
            notificationLoanList.push({
              // 'PaymentDate': loan.PaymentDate,
              'is_shown_start_period': new Date(loan.PaymentDate) > now ? false : true,
              'sellingDate': loan.sellingDate,
              'LoanID': loan.LoanID
            })
          }
        }
        const request = {
          data: {
            ClientID: this.ClientID,
            notificationLoanList,
          },
          url: rest._constructURL(config.urls.addPopupNotificationList)
        }

        rest
          ._request(request)
          .then((response) => {
            try {
              if (response.data['isError']) {
                console.log(response.data['errorMessage'])
              } else {
              }
            } catch (e) {
              console.log(e)
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
          })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.filter {
  margin-bottom: 70px;

  &__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__item {
    display: inline-block;
    padding: 12px 38px;
    font-weight: 500;
    font-size: 20px;
    border-radius: 12px;
    color: $color-text-dark;
    border: 1px solid $color-text-dark;
    cursor: pointer;

    &.active {
      color: $color-text-black;
      background-color: $color-gold;
      border-color: $color-gold;
    }
  }
}

.pagination {
  margin: 20px 0;

  &__list {
    display: inline-block;
    vertical-align: bottom;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: inline-block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    color: $color-text-gold;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    border-radius: 50%;
    border: 0;

    &--current {
      border: 1px solid $color-text-gold;
    }

    &--next {
      display: inline-block;
      vertical-align: bottom;
      background-color: $color-gold;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px 12px;
      background-image: url('@/assets/i/icons/arrow_double_right.svg');

      &:disabled {
        display: none;
      }
    }

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    }
  }
}

@media screen and (max-width: 1000px) {
  .filter {
    margin-bottom: 20px;

    &__list {
      gap: 20px;
    }

    &__item {
      padding: 10px;
      font-size: 14px;
    }

    &__name {

    }
  }
}
</style>
