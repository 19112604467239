<template>
  <div class="login-page">
    <div class="container">
      <div class="content">
        <div class="login-form">
          <FormAuth />
        </div>
      </div>
      <!-- content -->
    </div>
    <!-- container -->

    <InformationContent></InformationContent>
  </div>
</template>

<script>
import FormAuth from '../components/FormAuth.vue'
import InformationContent from '../components/InformationContent.vue'

export default {
  name: 'LoginPage',
  components: {
    FormAuth,
    InformationContent
  },
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss"></style>
