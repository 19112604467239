<template>
  <div class="breadcrumbs-site" v-if="breadcrumbs">
    <div class="container">
      <div class="content">
        <div class="breadcrumbs">
          <div
            class="breadcrumbs__item"
            v-for="(bread, index) in breadcrumbs"
            :key="index"
          >
            <router-link
              v-if="bread.to"
              :to="bread.to"
              class="breadcrumbs__link"
            >
              <span class="breadcrumbs__item-name" v-text="bread.name"></span>
            </router-link>
            <span v-if="bread.to" class="breadcrumbs__separator"></span>
            <span
              v-if="!bread.to"
              class="breadcrumbs__item-name"
              v-text="bread.name"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbsSite',
  props: {},
  data() {
    return {
      breadcrumbs: undefined
    }
  },
  computed: {},
  methods: {
    updateBreadcrumbs() {
      this.breadcrumbs = this.$route.meta.breadcrumbs
    }
  },
  watch: {
    $route() {
      this.updateBreadcrumbs()
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  margin: 20px 0;

  &-site {
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    font-weight: 300;
    font-size: 14px;
  }

  &__link {
    font-style: italic;
    text-decoration-line: underline;
    color: $color-text-gray;
  }

  &__separator {
    display: inline-block;
    margin: 0 10px;
    width: 14px;
    height: 8px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    background-image: url('@/assets/i/icons/separator_breadcrumbs.svg');
  }
}
</style>
