import { format } from 'fecha'

// import _keys from 'lodash/keys'
// function getCacheKey(ns, type, data) {
//   let key = ns + ':' + type

//   if (data) {
//     key += ':' + JSON.stringify(data, _keys(data).sort())
//   }

//   return key
// }

const helpers = {
  formatDate: (value, template) => {
    return format(value, template)
  },

  formatTel: (number) => {
    if (number) {
      // eslint-disable-next-line
      return `tel:${number.replace(/[^\d\+]/g, '')}`
    }

    return false
  },
  validateEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      ? false
      : true
  }
  // ,

  // toPrice: (value, params) => {
  //   if (!params) {
  //     params = {}
  //   }
  //   value = value + ''
  //   let text = ''
  //   let l = value.length
  //   let i = 0
  //   let k
  //   while (i < l) {
  //     k = ((i === 0) && (l % 3 > 0)) ? l % 3 : 3
  //     if (i + k < l) {
  //       text = text + value.substr(i, k) + ' '
  //     } else {
  //       text = text + value.substr(i, k)
  //     }
  //     i = i + k
  //   }
  //   if (params.sign) {
  //     text = text + ' ' + params.sign
  //   }
  //   return text
  // }
}

export default {
  install(app, options) {
    app.helpers = helpers
    app.config.globalProperties.$helpers = helpers
  }
}
