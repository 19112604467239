<template>
  <HeaderSite />
  <HeaderMobile />

  <div id="appBody" class="app-body">
    <BreadcrumbsSite></BreadcrumbsSite>

    <router-view></router-view>

    <NoticeModal></NoticeModal>
  </div>

  <FooterSite></FooterSite>
  <FormAuthModal></FormAuthModal>
</template>

<script>
import HeaderSite from './components/HeaderSite.vue'
import HeaderMobile from './components/HeaderMobile.vue'
import FooterSite from './components/FooterSite.vue'
import FormAuthModal from './components/FormAuthModal.vue'
import NoticeModal from './components/NoticeModal.vue'
import BreadcrumbsSite from './components/BreadcrumbsSite.vue'

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    BreadcrumbsSite,
    HeaderSite,
    HeaderMobile,
    FormAuthModal,
    FooterSite,
    NoticeModal,
  },
  computed: {
    // ...mapState(useStore, ['isBlur'])
  },
  watch: {
      $route: {
          immediate: true,
          handler(to) {
              document.title = to.meta.title + ' - Золотые медведи' || 'Золотые медведи';
          }
      },
  },
  methods: {},
}
</script>

<style lang="scss">
#app {
  background-color: #fbfbfb;
}

.app-body {
  padding-bottom: 40px;
}

.blur {
  filter: blur(10px) opacity(25%);
}

.vfm__overlay {
  filter: blur(10px) opacity(25%);
  background-color: transparent;
}
</style>
