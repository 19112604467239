<template>
  <div class="404-page">
    <div class="container">
      <div class="content">
        <h1 class="common-h1">Страница не найдена</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss"></style>
