<template>
  <vue-final-modal
    v-model="isShowLoginModal"
    classes="modal-container"
    content-class="modal-content"
    @closed="isShowLoginModal = false"
  >
    <div class="modal-close" @click="isShowLoginModal = false"></div>

    <FormAuth :type="'form-modal'" />

  </vue-final-modal>
</template>

<script>
import FormAuth from '../components/FormAuth.vue'
import { mapWritableState } from 'pinia'
import { useStore } from '../stores/store'

export default {
  name: 'FormAuthModal',
  props: {
  },
  components: {
    FormAuth
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    ...mapWritableState(useStore, ['isShowLoginModal'])
  }
}
</script>

<style lang="scss">
</style>
