<template>
    <ul class="middle-menu" v-if="ClientID">
        <li class="middle-menu__item">
            <router-link @click="isShowHeaderMobile = false" class="middle-menu__link" :to="{ name: 'LoanList' }">Мои займы</router-link>
        </li>
        <li class="middle-menu__item">
            <a @click="isShowHeaderMobile = false" href="#" class="middle-menu__link">Условия оплаты</a>
        </li>
        <li class="middle-menu__item">
            <router-link @click="isShowHeaderMobile = false" :to="{ name: 'NotificationPage' }" class="middle-menu__link">Настроить уведомления
            </router-link>
        </li>
    </ul>
</template>


<script>
import { mapState, mapWritableState } from 'pinia'
import { useStore } from '../stores/store'

export default {
    name: 'HeaderMenuMiddle',
    components: {
    },
    props: {},
    data() {
        return {
        }
    },
    computed: {
        ...mapState(useStore, ['ClientID']),
        ...mapWritableState(useStore, ['isShowHeaderMobile'])
    }
}
</script>

<style scoped lang="scss">

.middle-menu {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
  }

  &__link {
    color: $color-text-black;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-text-gold;
    }
  }
}


@media screen and (max-width: 1520px) {
  .middle-menu {
    &__item {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 1380px) {
  .middle-menu {
    gap: 1.2rem;
  }
}

@media screen and (max-width: 1280px) {
  .middle-menu {
  }
}

@media screen and (max-width: 1000px) {
  .middle-menu {
      display: block;
      border-bottom: 1px solid #CACACA;
      margin-bottom: 20px;

      &__item {
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 24px;
      }

      &__link {
        font-size: 18px;
      }
  }
}


</style>