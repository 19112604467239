<template>
    <vue-final-modal
      v-model="isShowTerms"
      classes="modal-container"
      content-class="modal-content modal-content--wide"
      @closed="isShowTerms = false"
    >
      <div class="modal-close" @click="isShowTerms = false"></div>
      <div class="modal-inner common-html" v-html="termsHtml"></div>
    </vue-final-modal>
</template>


<script>
import { mapWritableState } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'TermsModal',
  components: {},
  props: {},
  data() {
    return {
      termsHtml: null
    }
  },
  computed: {
    ...mapWritableState(useStore, ['isShowTerms'])
  },
  methods: {
  },
  mounted() {
    if (!this.termsHtml) {
        let request = {
          method: 'get',
          params: {
            format: 'json',
            slug: 'terms'
          },
          url: rest._constructURL(config.urls.simplePages)
        }

        rest
          ._request(request)
          .then((response) => {
            try {
              this.termsHtml = response.data[0].text
            } catch (e) {
              console.log(e)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }

  }
}
</script>