import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import LoanList from '../views/LoanList.vue'
import LoanDetail from '../views/LoanDetail.vue'
import LoyaltyProgram from '../views/LoyaltyProgram.vue'
import HelpPage from '../views/HelpPage.vue'
import NotificationPage from '../views/NotificationPage.vue'
import PageNotFound from '../views/PageNotFound.vue'
// import Empty from '../views/Empty.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Личный кабинет',
      requireAuth: false
    }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      title: 'Вход',
      requireAuth: false,
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          to: { name: 'HomePage' }
        }
      ]
    }
  },
  {
    path: '/loans',
    name: 'LoanList',
    component: LoanList,
    meta: {
      title: 'Залоговые билеты',
      requireAuth: true,
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          to: { name: 'HomePage' }
        },
        {
          name: 'Мои займы'
        }
      ]
    },
    children: [
      {
        path: ':LoanID',
        name: 'LoanDetail',
        props: true,
        component: LoanDetail,
        meta: {
          title: 'Залоговый билет',
          requireAuth: true,
          breadcrumbs: [
            {
              name: 'Личный кабинет',
              to: { name: 'HomePage' }
            },
            {
              name: 'Мои займы',
              to: { name: 'LoanList' }
            },
            {
              name: 'Билет'
            }
          ]
        }
      }
    ]
  },
  {
    path: '/loyalty',
    name: 'LoyaltyProgram',
    props: true,
    component: LoyaltyProgram,
    meta: {
      title: 'Программа лояльности',
      requireAuth: false,
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          to: { name: 'HomePage' }
        },
        {
          name: 'Программа лояльности'
        }
      ]
    }
  },
  {
    path: '/help',
    name: 'HelpPage',
    props: true,
    component: HelpPage,
    meta: {
      title: 'Помощь',
      requireAuth: false,
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          to: { name: 'HomePage' }
        },
        {
          name: 'Помощь'
        }
      ]
    }
  },
  {
    path: '/notification',
    name: 'NotificationPage',
    props: true,
    component: NotificationPage,
    meta: {
      title: 'Уведомления',
      requireAuth: true,
      breadcrumbs: [
        {
          name: 'Личный кабинет',
          to: { name: 'HomePage' }
        },
        {
          name: 'Настроить уведомления'
        }
      ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      title: 'Страница не найдена - 404',
      requireAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0, behavior: 'smooth' }
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requireAuth && !localStorage.ClientID) {
    next({ name: 'HomePage' })
  } else if (localStorage.ClientID && to.name == 'HomePage') {
    next({ name: 'LoanList' })
  } else {
    next()
  }
})

export default router
