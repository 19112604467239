<template>
  <div class="home-page">
    <div class="home-banner">
      <div class="container">
        <div class="content">
          <div class="home-banner__inner">
            <div class="home-intro" v-show="!isShowLoginForm">
              <div class="home-intro__subtitle">{{ homeSubtitle }}</div>
              <div class="home-intro__title" v-html="homeTitle"></div>
              <div
                class="home-intro__sublist html-content"
                v-html="homeSubtitleList"
              ></div>
              <div class="home-intro__buttons">
                <div
                  class="home-intro__button common-button common-button--gold"
                  @click="isShowLoginForm = true"
                >
                  Вход
                </div>
              </div>
            </div>

            <div class="home-form" v-show="isShowLoginForm">
              <FormAuth :type="'form-block'" />
            </div>
          </div>
        </div>
        <!-- content -->
      </div>
      <!-- container -->
    </div>
    <!-- home-banner -->

    <InformationContent></InformationContent>
  </div>
</template>

<script>
import FormAuth from '../components/FormAuth.vue'
import InformationContent from '../components/InformationContent.vue'

export default {
  name: 'HomePage',
  components: {
    FormAuth,
    InformationContent
  },
  props: {},
  data() {
    return {
      isShowLoginForm: false,
      homeTitle: 'Личный кабинет <br>клиента',
      homeSubtitle: 'Сеть ломбардов “Золотые медведи”',
      homeSubtitleList: `
        <ul>
          <li>Узнайте свою задолженность по залоговым билетам</li>
          <li>Запланируйте дату следующего платежа</li>
          <li>Оплатите проценты онлайн</li>
        </ul>
      `
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.home-banner {
  background-color: #fff;
  box-shadow: inset 0px 10px 20px rgba(0, 0, 0, 0.09);

  &__inner {
    margin: 0;
    min-height: 700px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0;
    background-image: url('@/assets/i/home_banner.svg');
  }
}

.home-intro {
  padding-top: 12%;
  width: 630px;
  box-sizing: border-box;

  &__title {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 16px;
  }

  &__subtitle {
    text-transform: uppercase;
    color: $color-text-gray;
    margin-bottom: 16px;
  }

  &__sublist {
    font-size: 16px;
    font-weight: 400;
    color: $color-text-black;
    margin-bottom: 50px;
  }

  &__buttons {
    margin-bottom: 20px;
    display: flex;
    gap: 30px;
  }

  &__button {
    font-weight: 700;
    font-size: 18px;
  }
}

.home-form {
  padding-top: 4%;
  max-width: 660px;
}

@media screen and (max-width: 1280px) {
  .home-intro {
    width: 50%;
  }

  .home-banner {
    &__inner {
      background-size: 50%;
    }
  }
}
@media screen and (max-width: 1000px) {
  .home-form {
    padding-top: 0;
    max-width: none;
  }

  .home-banner__inner {
    min-height: 500px;
  }
}
@media screen and (max-width: 600px) {
  .home-intro {
    width: 100%;
    padding: 20px;
  }

  .home-banner__inner {
    min-height: auto;
    background: none;
  }
}
</style>
