<template>
  <div class="loan-card">
    <div class="loan-card__header">
      <div class="loan-card__ticket">Билет №{{ loanCard.pawnBillNumber }}</div>
      <div
        class="loan-card__select"
        :class="{ 'loan-card__select--favorites': loanCard.favorite }"
        @click="toggleFavorites(loanCard.LoanID)"
      ></div>
    </div>
    <div class="loan-card__status">
      <div
        class="loan-card__status-name"
        :class="statusClass"
        v-text="getLoanStatusName(loanCard)"
      ></div>
      <div class="loan-card__status-date">
        {{ $helpers.formatDate(new Date(loanCard.LoanDate), 'DD.MM.YYYY') }}
      </div>
    </div>
    <div class="loan-card__params">
      <table class="loan-card__params-table">
        <tr>
          <td>Сумма займа (с %):</td>
          <td>{{ loanCard.fullDebt }} ₽</td>
        </tr>
        <tr>
          <td>% на сегодня:</td>
          <td>{{ loanCard.prolongationSum }} ₽</td>
        </tr>
        <tr>
          <td>Дата оформления:</td>
          <td>
            {{ $helpers.formatDate(new Date(loanCard.LoanDate), 'DD.MM.YYYY') }}
          </td>
        </tr>
        <tr>
          <td>Дата окончания:</td>
          <td>
            {{
              $helpers.formatDate(new Date(loanCard.PaymentDate), 'DD.MM.YYYY')
            }}
          </td>
        </tr>
      </table>
    </div>
    <div class="golden-list loan-card__products">
      <div class="loan-card__products-title">Предмет залога</div>
      <ul v-if="loanCard.products">
        <li
          v-for="(productItem, index) in loanCard.products"
          v-text="productItem.name"
          :key="index"
        ></li>
      </ul>
      <div v-else>{{ loanCard.ShortLoanDescription }}</div>
    </div>
    <div class="loan-card__more">
      <router-link
        :to="{ name: 'LoanDetail', params: { LoanID: loanCard.LoanID }}"
        class="loan-card__more-link common-button common-button--gold common-button--medium"
        >Подробнее</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'LoanCard',
  props: {
    loanCard: Object,
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(useStore, ['getLoanStatusName', 'getLoanStatus']),

    toggleFavorites(LoanID) {
      if (!this.loanList.length) return
      if (!localStorage.ClientID || localStorage.ClientID === undefined) return

      const loader = this.$loading.show(this.$loaderProps)

      const request = {
        data: {
          ClientID: localStorage.ClientID,
          LoanID
        },
        url: rest._constructURL(config.urls.toggleFavorites)
      }

      rest
        ._request(request)
        .then((response) => {
          try {
            if (response.data.result) {
              this.favorites.add(LoanID)
              this.loanList.filter(
                (loanCard) => loanCard.LoanID === LoanID
              )[0].favorite = true
            } else {
              this.favorites.delete(LoanID)
              this.loanList.filter(
                (loanCard) => loanCard.LoanID === LoanID
              )[0].favorite = false
            }
          } catch (e) {
            console.log(e)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loader.hide()
        })
    }
  },
  computed: {
    ...mapWritableState(useStore, ['loanList', 'favorites']),
    statusClass() {
      return 'loan-card__status-name--' + this.getLoanStatus(this.loanCard)
    }
  }
}
</script>

<style scoped lang="scss">
.loan-card {
  padding: 20px 20px 28px 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 1rem;
  }

  &__ticket {
    font-weight: 600;
    font-size: 20px;
  }

  &__select {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-image: url('@/assets/i/icons/star_gray.svg');
    cursor: pointer;

    &--favorites {
      background-image: url('@/assets/i/icons/star_gold.svg');
    }
  }

  &__status {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &-name {
      padding: 6px 18px;
      font-weight: 700;
      font-size: 16px;
      border-radius: 12px;

      &--overdue {
        border: 1px solid $color-red;
        color: $color-red;
      }

      &--active {
        border: 1px solid $color-gold;
        color: $color-gold;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 16px;
    }
  }

  &__params {
    margin-bottom: 40px;

    &-table {
      table-layout: auto;
      width: 100%;

      td {
        &:first-child {
          font-weight: 400;
          color: $color-text-gray;
          padding-right: 5px;
        }

        &:nth-child(2) {
          text-align: right;
          font-weight: 600;
          color: $color-text-black;
        }
      }
    }
  }

  &__products {
    margin-bottom: 30px;
    flex-grow: 1;

    ul li {
      font-size: 14px;
    }

    &-title {
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      color: $color-text-gray;
    }
  }

  &__more {
    margin: 0 auto;
    text-align: center;
    flex-grow: 0;

    &-link {
      display: inline-block;
      padding: 15px 15px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      background: $color-gold;
      border-radius: 25px;
      text-decoration: none;
      text-transform: uppercase;
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1200px) {
  .loan-card__status-name {
    font-size: 14px;
    padding: 6px 8px;
  }
}

@media screen and (max-width: 1000px) {
  .loan-card {
    padding: 10px 10px 18px 16px;

    &__more-link {
      padding: 10px;
    }
  }
}
</style>
