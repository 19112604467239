import axios from 'axios'
import { config, requisites } from '../settings'

class RestApi {
  default = {
    method: 'post',
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${config.token}`
    }
  }

  constructor() {}

  _request(adv = {}) {
    const request = Object.assign({}, this.default, adv)
    return axios(request)
  }

  _constructURL(url) {
    let result = config.protocol + '://' + config.host
    if (config.port) {
      result = result + ':' + config.port
    }
    return result + config.api + url
  }

  _QRString(params) {
    let result = Object.assign({}, requisites, params)
    return `ST00012|Name=${result.Name}|PersonalAcc=${result.PersonalAcc}|BankName=${result.BankName}|BIC=${result.BIC}|CorrespAcc=${result.CorrespAcc}|KPP=${result.KPP}|PayeeINN=${result.PayeeINN}|Purpose=${result.Purpose}|Sum=${result.Sum}`
  }

  _auth(form) {
    // Формат получения ClientId
    // 19832706 18057722123
    // yyyyDDMM SSSSNNNNNNN

    const birthArr = form.birthDate.split('.')
    const queryString =
      birthArr[2] +
      birthArr[1] +
      birthArr[0] +
      ' ' +
      // авторизация по ЗБ
      form.pawnBillNumber
      // авторизация по паспорту
      //form.passportSerial +
      //form.passportNumber

    const request = {
      data: { queryString },
      url: form.url
    }

    return this._request(request)
  }
}

export { RestApi }
