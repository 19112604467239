<template>
  <div :class="type">
    <FormKit
      type="form"
      presave
      v-model="authForm"
      :classes="{
        form: 'auth-form'
      }"
      @submit="submit"
      :actions="false"
    >

      <h4 class="auth-form__title">Вход в кабинет</h4>
      <div class="auth-form__fieldgroup">
        <div class="w100">
          <FormKit
            :type="dateMask"
            label="Дата рождения"
            name="birthDate"
            :classes="{
              outer: 'w100',
              label: 'auth-form__label',
              input: 'auth-form__input',
            }"
            validation="required|matches:/^\d{2}\.\d{2}\.\d{4}$/"
            placeholder="ДД.ММ.ГГГГ"
            maxlength="10"
          />
          <!-- validation="required|matches:/^\d{2}\.\d{2}\.\d{4}$/" -->
        </div>
        <template
          v-if="authByPawnBillNumber"
        >
          <FormKit
            type="text"
            name="pawnBillNumber"
            :classes="{
              label: 'auth-form__label',
              input: 'auth-form__input',
            }"
            label="№ Залогового билета"
            validation="required|matches:/^[a-zA-zа-яА-я]{2}\d+$/"
            placeholder=""
          />
        </template>
        <template
          v-else
        >
          <FormKit
            type="text"
            name="passportSerial"
            :classes="{
              label: 'auth-form__label',
              input: 'auth-form__input',
            }"
            label="Серия паспорта"
            validation="required"
            placeholder="0000"
          />
          <FormKit
            type="text"
            name="passportNumber"
            :classes="{
              label: 'auth-form__label',
              input: 'auth-form__input',
            }"
            label="Номер паспорта"
            validation="required"
            placeholder="000000"
          />
        </template>
        <FormKit
          type="checkbox"
          name="terms"
          :sections-schema="{
              // outer: { $el: null },
              // inner: { $el: null },
              // label: { $el: null }
          }"
          :classes="{
              outer: 'formkit-checkbox'
          }"
          label="Я ознакомлен и согласен с условиями политики обработки персональных данных и конфиденциальности"
          validation="required"
          validation-visibility="dirty"
        />

      </div>

      <div class="auth-form__submit">
        <FormKit
          type="submit"
          :classes="{
            input: 'auth-form__submit-button common-button common-button--gold',
          }"
        >
          Войти
        </FormKit>
      </div>

      <div class="auth-form__message" v-show="isError">Пользователь не найден. Проверьте правильность заполнения</div>
    </FormKit>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'
import { createInput } from '@formkit/vue'
import DateMask from './input/date-mask.vue'

let rest = new RestApi()

export default {
  name: 'FormAuth',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dateMask: createInput(DateMask),
      authByPawnBillNumber: true,
      authForm: {
        // passportSerial: 'ЛН',
        // passportNumber: '001320',
        // birthDate: '13.03.1979'

        // passportSerial: 'ГА',
        // passportNumber: '002093',
        // birthDate: '20.07.1965'

        // passportSerial: '6907',
        // passportNumber: '202326',
        // birthDate: '22.03.1962',

        // passportSerial: '6919',
        // passportNumber: '911557',
        // birthDate: '22.05.1975'

        // birthDate: birthDate.value
      },
      isError: false,
    }
  },
  methods: {
    ...mapActions(useStore, ['login']),
    submit(e) {
      const loader = this.$loading.show(this.$loaderProps)
      this.authForm.url = rest._constructURL(config.urls.getClientID)

      rest
        ._auth(this.authForm)
        .then((response) => {
          try {
            if (response.data.ClientID === undefined) {
              // console.log(response.data['errorMessage'])
              this.isError = true
            } else {
              this.login(response.data['ClientID'])
              this.isShowLoginModal = false
              this.$router.push({ name: 'LoanList' })
            }
          } catch (e) {
            console.log(e)
            this.isError = true
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loader.hide()
        })
    }
  },
  computed: {
    ...mapState(useStore, ['ClientID']),
    ...mapWritableState(useStore, ['isShowTerms', 'isShowLoginModal'])
  }
}
</script>

<style lang="scss">
.auth-form { 
  &__terms {
    display: flex;
    position: relative;
    flex-flow: column;
    margin-left: 28px;
    font-size: 12px;
    line-height: 15px;
    color: #999;
    max-width: 600px;

    &-link {
      text-decoration: none;
      color: $color-text-black;
      cursor: pointer;
    }
  }

  &__message {
    padding: 20px 0;
    color: $color-red;
    font-size: 16px;
    font-weight: 400;
  }
}

.form-block {
  .auth-form {
    &__title {
      display: none;
    }

    &__fieldgroup {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 140px 0 60px;
    }
  
    &__label {
      display: block;
      font-weight: 400;
      font-size: 18px;
      color: $color-text-black;
      margin-bottom: 16px;
    }
  
    &__input {
      width: 275px;
  
      &--mask {
        // position: absolute;
      }
    }
  
    &__submit {
      &-button {
        width: 180px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .auth-form {
      &__submit {
        &-button {
        }
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .auth-form {
      &__fieldgroup {
        display: block;
        padding: 20px 0;
      }
  
      &__input {
        width: 100%;
      }
    }
  }
}

.form-modal {
  .auth-form {
    &__fieldgroup {
      margin-bottom: 40px;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: $color-text-black;
      margin-bottom: 40px;
    }

    &__input {
      margin-bottom: 24px;
      width: 100%;
    }

    &__label {
      display: block;
      font-weight: 500;
      font-size: 16px;
      color: $color-text-gray;
      margin-bottom: 6px;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    &__submit {
      text-align: center;
      margin-bottom: 20px;
      &-button {
      }
    }
  }
}
</style>
