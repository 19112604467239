<template>
  <div class="container">
    <div class="content">
      <div class="information-content common-text">
        <div class="information-content__block" v-if="contentBlock1">
          <div class="html-content" v-html="contentBlock1"></div>
        </div>
        <div class="information-content__block">
          <div class="html-content" v-html="contentBlock2"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="information__content">
    <div class="information__block">
      <h2 class="title">Инструкция по работе в личном кабинете (далее – ЛК)</h2>
      <div class="information__instruction instruction">
          <ul class="instruction__tabs">
            <li v-for="(tab, index) in tabs" :class="{ 'is-active': tab.isActive }">
              <a class="instruction__link" @click="selectTab(tab, details, index)"><span>{{ tab.nameFirst }}</span><p>{{ tab.nameSecond }}</p></a>
            </li>
          </ul>

        <div class="instruction__details">
          <div class="instruction__detail" v-for="(detail, index) in details" v-show="detail.isActive1">
            <div class="left">
              <p class="title">{{detail.name}}</p>
              <p class="descr" v-html="detail.descr"></p>
            </div>
            <div class="right">
              <img :src="detail.img_path">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="content">
      <div class="information-content common-text">
        <div class="information-content__block">
          <div class="html-content" v-html="contentBlock3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'InformationContent',
  props: {},
  data() {
    return {
      contentBlock1: "",
      contentBlock2: "",
      contentBlock3: "",
      tabs: [
        { nameFirst: "Шаг 1", nameSecond: "Личный кабинет", isActive: "active" },
        { nameFirst: "Шаг 2", nameSecond: "Вход" },
        { nameFirst: "Шаг 3", nameSecond: "Авторизация"},
        { nameFirst: "Шаг 4", nameSecond: "Раздел «Мои займы»" },
        { nameFirst: "Шаг 5", nameSecond: "Билеты" },
        { nameFirst: "Шаг 6", nameSecond: "Оплата" }
      ],
      details: [
        { name: "Личный кабинет", img_path: require('../assets/img/instr_1.png'), descr: "Входим в личный кабинет с сайта <a style='color:#E2B155' href='https://www.zmed.ru'>www.zmed.ru</a>", isActive1: "active" },
        { name: "Вход", img_path: require('../assets/img/instr_2.png'), descr: "На странице ЛК нажимаем ВХОД" },
        { name: "Авторизация", img_path: require('../assets/img/instr_3.png'), descr: "Набираем дату рождения (деннь, месяц, год) и номер Залогового билета.<br>Ставим галочку на согалсие обработки данных."},
        { name: "Раздел «Мои займы»", img_path: require('../assets/img/instr_4.png'), descr: "В этом разделе Вам видны все займы, история начислений и платежей. Выбирайте и оплачивайте  " },
        { name: "Билеты", img_path: require('../assets/img/instr_5.png'), descr: "Выбираем нужный билет для продления путем оплаты процентов. Программа предложит два варианта оплаты:\n" +
                  "<ul> <li><b>По QR-code</b> (сканировать QR-код, без комиссии), или</li>" +
                  "<li><b>Оплата банковской картой</b> (оператор взимает комиссию 2,5%)</li></ul>" },
        { name: "Оплата", img_path: require('../assets/img/instr_6.png'), descr: "Выберите способ оплаты «банковской картой» или \n" +
                  "«По QR коду»\n" +
                  "<ul><li>Отсканируйте QR-код с помощью камеры на своем телефоне. Вы будете перенаправлены в приложение Вашего банка;</li>" +
                  "<li>Скачайте QR-код и отсканируйте его в приложении Вашего банка.</li></ul>" +
                  "или оплатите картой (заполнить, где указано стрелочками)" }
      ],
      isActive: false,
      isActive1: false
    }
  },
  methods: {
    selectTab(selectedTab, details, index) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.nameSecond == selectedTab.nameSecond);
      });
      details.forEach(detail => {
        detail.isActive1 = (detail.name == selectedTab.nameSecond);
      });
    }
  },
  async created() {
    let request = {
      method: 'get',
      params: {
        format: 'json',
      },
      url: rest._constructURL(config.urls.simplePages)
    }
    request.params.slug = 'home_1'
    await rest
      ._request(request)
      .then((response) => {
        try {
          this.contentBlock1 = response.data[0].text
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    request.params.slug = 'home_2'
    await rest
      ._request(request)
      .then((response) => {
        try {
          this.contentBlock2 = response.data[0].text
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    request.params.slug = 'home_3'
    await rest
      ._request(request)
      .then((response) => {
        try {
          this.contentBlock3 = response.data[0].text
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
  .information-content {
    padding: 70px 0;

    &__block {
      padding: 30px 20px;
      margin-bottom: 40px;
      background-color: #fff;
    }
  }

  .information__content {
    background: white;
    padding: 10px 0 30px;
  }

  .information__block {
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 15px;

    & .title {
      text-align: center;
    }
  }

  .instruction {
    &__block .title {
      text-align: center;
    }

    &__tabs {
      display: flex;
      padding: 0;

      & li {
        width: 100%;
        height: 45px;
        background-image: url("@/assets/i/icons/bg_base.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        list-style: none;
        padding: 10px 10px 10px 24px;
        cursor: pointer;

        & a {
          text-decoration: none;
        }

        & span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #BDBDBD;
        }

        & p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #333333;
          margin: 0;
        }

        &.is-active {
          background-image: url("@/assets/i/icons/bg_base_gold.svg");

          & span {
            color: #4F4F4F;
          }

          & p {
            font-weight: 700;
          }
        }
      }
    }
    &__detail {
      display: flex;

      & .left {
        width: 40%;
        padding-right: 23px;
      }
      & .right {
        width: 60%;
      }

      & .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-transform: uppercase;
        text-align: left;
        color: #E2B155;
        margin-bottom: 0;
      }

      & img {
        width: 100%;
        margin-top: 40px;
      }
    }
    &__link {
      display: block;
      width: inherit;
    }
    &__info {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  @media (max-width: 1440px) {
    .instruction__tabs {
      overflow-x: scroll;
      overflow-y: hidden;
      padding-bottom: 10px;

      & li {
        width: 200px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .instruction__detail {
      flex-direction: column;

      & .left,
      & .right {
        width: 100%;
      }

      & .title {
        font-size: 28px;
        line-height: 38px;
      }

      & img {
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .information-content {
      padding: 20px 0;
    }
    .instruction__tabs li {
        width: 184px;
    }
  }
</style>