<template>
  <div class="container">
    <div class="content">
      <h1 class="common-h1">Настроить уведомления</h1>

      <FormKit
        type="form"
        v-model="notifForm"
        :classes="{
          form: 'notif-form'
        }"
        @submit="save"
        :actions="false"
      >
        <div class="notif-form__title">
          Введите E-mail, на который будет приходить напоминание об оплате
        </div>

        <FormKit
          type="text"
          name="email"
          :classes="{
            input: 'notif-form__email',
          }"
          placeholder="e-mail"
          validation="required|email"
        />
        <FormKit
          v-model="notifications"
          name="notifications"
          type="checkbox"
          :options="{
            1: 'Прислать уведомление за 1 день',
            3: 'Прислать уведомление за 3 дня',
            7: 'Прислать уведомление за 7 дней',
          }"
          :classes="{
            wrapper: 'notif-form__wrapper',
            options: 'notif-form__options',
            option: 'notif-form__option',
            input: 'notif-form__checkbox-input',
            legend: 'notif-form__checkbox-legend',
            decorator: 'notif-form__checkbox-decorator'
          }"
          label="Присылать уведомления об оплате"
          validation=""
          :sections-schema="{
            fieldset: { $el: null },
            inner: { $el: null },
            label: { $el: null }
          }"
        />
        <div class="notif-form__action">
          <FormKit
            type="submit"
            :classes="{
              input: 'common-button common-button--gold'
            }"
          >
            Сохранить
          </FormKit>

          <Transition name="fade" mode="out-in">
            <div class="notif-form__saved" v-show="isShowSaved">Сохранено</div>
          </Transition>
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'HelpPage',
  components: {},
  props: {},
  data() {
    return {
      notifForm: {},
      notifications: null,
      isShowSaved: false
    }
  },
  methods: {
    save() {
      let request = {
        data: {
          notifications: this.notifications,
          email: this.notifForm.email ? this.notifForm.email : '',
          ClientID: this.ClientID
        },
        url: rest._constructURL(config.urls.saveNotification)
      }

      rest
        ._request(request)
        .then((response) => {
          try {
            this.isShowSaved = true
            setTimeout(() => {
              this.isShowSaved = false
            }, 2000)
          } catch (e) {
            console.log(e)
          }
        })
        .catch((error) => {
          console.log(error)
        })
      return
    }
  },
  computed: {
    ...mapState(useStore, ['ClientID'])
  },
  async created() {
    let request = {
      method: 'get',
      params: {
        ClientID: this.ClientID
      },
      url: rest._constructURL(config.urls.getNotification)
    }

    await rest
      ._request(request)
      .then((response) => {
        try {
          if (response.data[0]) {
            this.notifForm.email = response.data[0]['email']
            this.notifForm.notifications = JSON.parse(
              response.data[0]['notifications'].replaceAll("'", '')
            )
          } else {
            console.log('Notifications is not set')
          }
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss">
.notif-form {
  &__title {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333;
  }

  &__email {
    width: 300px !important;
    margin-bottom: 20px;
  }

  &__options {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 40px;
  }

  &__option {
    margin-bottom: 10px;
  }

  &__wrapper {
  }

  &__checkbox {
    &-legend {
      display: block;
      margin-bottom: 16px;
    }

    &-input {
      user-select: none;
      font-size: 0;
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 0 !important;
      vertical-align: middle;

      &:checked + .notif-form__checkbox-decorator {
        background-image: url('@/assets/i/icons/checked.svg');
      }
    }

    &-decorator {
      content: '';
      display: inline-block;
      margin-right: 10px;
      vertical-align: bottom;
      width: 15px;
      height: 15px;
      border: 1px solid #a2aabc;
      border-radius: 3px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 90% 70%;
      background-color: #fff;
    }
  }

  &__action {
    display: flex;
    gap: 40px;
  }

  &__saved {
    padding: 15px 40px;
    color: #fff;
    background-color: #a4e7a4;
    border-radius: 30px;
  }
}

@media screen and (max-width: 1280px) {
  .notif-form {
    &__saved {
      padding: 6px 40px;
    }
  }
}
@media screen and (max-width: 1000px) {
  .notif-form {
    &__email {
      width: 100% !important;
    }

    &__saved {
      padding: 6px 40px;
    }
  }
}
</style>
