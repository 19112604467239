<template>
  <ul class="top-menu">
      <li class="top-menu__item">
      <router-link class="top-menu__link" :to="{ name: 'HelpPage' }" @click="isShowHeaderMobile = false">
          Помощь
      </router-link>
      </li>
      <li class="top-menu__item">
      <router-link
          class="top-menu__link"
          :to="{ name: 'LoyaltyProgram' }"
          @click="isShowHeaderMobile = false"
          >Программа лояльности</router-link
      >
      </li>
  </ul>
</template>


<script>
import { mapWritableState } from 'pinia'
import { useStore } from '../stores/store'

export default {
    name: 'HeaderMenuMiddle',
    components: {
    },
    props: {},
    data() {
        return {
        }
    },
    methods: {
    },
    computed: {
      ...mapWritableState(useStore, ['isShowHeaderMobile'])
    }
}
</script>

<style scoped lang="scss">

.top-menu {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: inline-block;
    margin: 0 10px;
    /* line-height: normal; */
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $color-text-black;

    &:hover {
      color: $color-text-gold;
    }
  }
}

@media screen and (max-width: 1000px) {
  .top-menu {
    &__item {
      display: block;
      font-weight: 400;
      font-size: 18px;
      margin: 0 0 24px 0;
    }
  }
}
</style>
