const requisites = {
  Name: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЛОМБАРД-1"',
  PersonalAcc: '40701810404000000736',
  BankName: 'СИБИРСКИЙ Ф-Л ПАО "ПРОМСВЯЗЬБАНК" г.Новосибирск',
  BIC: '045004816',
  CorrespAcc: '30101810500000000816',
  KPP: '701701001',
  PayeeINN: '7017038083'
}

const config = {
  protocol: 'https',
  host: 'lk.zmed.ru',
  port: '',
  api: '/api',
  token: 'cb7d3ae2b403663de274cf0ce39040df85ec36a9',
  localhost: false,
  urls: {
    getClientID: '/lombardis/getClientID',
    getClientLoans: '/lombardis/getClientLoans',
    getLoanDetails: '/lombardis/getLoanDetails',
    requestPayment: '/lombardis/requestPayment',
    getClientDetails: '/lombardis/getClientDetails',
    getClientPayments: '/lombardis/getClientPayments',

    getFavorites: '/getFavorites',
    toggleFavorites: '/toggleFavorites',
    getLocation: '/getLocation',
    getQRSrc: '/getQRSrc',
    sendEmail: '/sendEmail',
    saveNotification: '/saveNotification',
    getEmail: '/getEmail',
    getPopupNotificationData: '/getPopupNotificationData',
    addPopupNotificationList: '/addPopupNotificationList',

    // DRF serialise
    faq: '/faq/',
    simplePages: '/simplePages/',
    getPhones: '/getPhones/',
    getNotification: '/getNotification/',
  }
}

export { config, requisites }
