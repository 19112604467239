import { defineStore } from 'pinia'
import { config } from '../settings'
import { RestApi } from '../services/service'
let rest = new RestApi()


export const useStore = defineStore('store', {
  state: () => ({
    favorites: new Set(),
    loanList: [],
    loan: null,
    filterList: [
      {
        name: 'Все билеты',
        code: 'all'
      },
      {
        name: 'Активные',
        code: 'active'
      },
      {
        name: 'Просроченные',
        code: 'overdue'
      },
      {
        name: 'Выкупленные',
        code: 'redeemed'
      },
      {
        name: 'Избранные',
        code: 'favorites'
      }
    ],
    filterCurrentStatus: 'all',
    paymentInfo: null,
    ClientID: localStorage.ClientID,
    username: localStorage.surname
      ? `${localStorage.surname} ${localStorage.name}`
      : null,
    useremail: localStorage.email ? localStorage.email : null,

    mainPhone: '+7 (800) 707 08 63',
    mainEmail: 'office@zmed.ru',

    isShowLoginModal: false,
    isShowHeaderMobile: false,
    isShowTerms: false,
    isShowNoticeModal: false,
    isShowRequisites: false,
    requestHtml: {},
  }),
  getters: {
    getAllLoans: (state) => {
      return state.loanList.sort(state.sortLoan)
    },
    getFilteredLoans: (state) => {
      if (state.loanList.length) {
        state.setFavoritesLoans()

        let result = state.loanList

        if (
          state.filterCurrentStatus !== 'all' &&
          state.filterCurrentStatus !== 'favorites'
        ) {
          result = result.filter(
            (loan) => state.getLoanStatus(loan) === state.filterCurrentStatus
          )
        }

        if (state.filterCurrentStatus === 'favorites') {
          result = result.filter((loan) => loan.favorite === true)
        }

        return result.sort(state.sortLoan)
      }

      return []
    },
  },
  actions: {
    login(ClientID) {
      this.ClientID = ClientID
      localStorage.ClientID = ClientID
    },
    logout() {
      localStorage.clear()
      this.$reset()
    },
    sortLoan(l1, l2) {
      // из пары один билет в избранном
      if (l1.favorite !== l2.favorite) return l1.favorite ? -1 : 1

      let s1 = this.getLoanStatus(l1)
      let s2 = this.getLoanStatus(l2)

      if (s1 == s2) return this.subSortByDate(l1, l2)

      if (s1 == 'active') return -1

      if (s2 == 'active') return 1

      if (s1 == 'overdue') return -1
      s1 == 'overdue' ? -1 : 1
    },
    subSortByLoanID(l1, l2) {
      if (l1.LoanID === l2.LoanID) return 0

      return l1.LoanID > l2.LoanID ? -1 : 1
    },
    subSortByDate(l1, l2) {
      let l1Date = new Date(l1.LoanDate).getTime()
      let l2Date = new Date(l2.LoanDate).getTime()
      if (l1Date === l2Date) return 0

      return l1Date < l2Date ? 1 : -1
    },
    getLoanStatusName(loan) {
      switch (this.getLoanStatus(loan)) {
        case 'active':
          return 'Активный'
        case 'overdue':
          return 'Ушло на аукцион'
        case 'redeemed':
          return 'Выкуплен'
      }
    },
    getLoanStatus(loan) {
      if (loan && loan.Closed) {
        return 'redeemed'
      } else {
        let sellingDate = loan.sellingDate || loan.SellingDate // Разные названия отдаёт Ломбардис из списка и детальной
        if (loan && Date.parse(sellingDate) > new Date()) {
          return 'active'
        } else {
          return 'overdue'
        }
      }
    },
    isLoanExist(LoanID) {
      return this.loanList.filter((loan) => loan.LoanID === LoanID).length > 0
    },
    addFavorite(LoanID) {
      this.favorites.add(LoanID)
    },
    EmailTemplateFAQ(data) {
      return `
        Тема письма: ${data.theme}

        Имя: ${data.name}

        E-mail: ${data.email}

        Раздел: ${data.cat}

        Вопрос: ${data.text}
      `
    },
    EmailTemplateNotice(data) {
      return `
        Тема письма: ${data.theme}

        Имя: ${data.name}

        E-mail: ${data.email}

        Телефон: ${data.phone}

        Вопрос: ${data.text}
      `
    },
    saveUserInfo(user) {
      this.username = `${user['surname']} ${user['name']}`
      this.useremail = user['email']

      localStorage.name = user['name']
      localStorage.surname = user['surname']
      localStorage.email = user['email']
      localStorage.phone = user['phone']
      localStorage.patronymic = user['patronymic']
    },
    getSimplePage(slug) {
      if (!this.requestHtml[slug]) {
        let request = {
          method: 'get',
          params: {
            format: 'json',
            slug
          },
          url: rest._constructURL(config.urls.simplePages)
        }

        rest
          ._request(request)
          .then((response) => {
            try {
              this.requestHtml[slug] = response.data[0].text
              return this.requestHtml[slug]
            } catch (e) {
              console.log(e)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        return this.requestHtml[slug]
      }

    },
    setFavoritesLoans() {
      if (this.loanList.length && this.favorites.size) {
        this.favorites.forEach((LoanID) => {
          let idx = this.loanList.findIndex((loan) => loan.LoanID === LoanID)
          if (idx >= 0) {
            this.loanList[idx].favorite = true
          }
        })
      }
    }
  }
})
