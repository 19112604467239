<template>
  <div class="vld-parent" v-show="LocationID" ref="location">
    <span>
      {{ location }}
    </span>
  </div>
</template>

<script>
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'LocationLoader',
  props: {
    LocationID: String
  },
  data() {
    return {
      location: null
    }
  },
  computed: {},
  methods: {},
  mounted() {
    const request = {
      data: { Code: this.LocationID },
      url: rest._constructURL(config.urls.getLocation)
    }

    const loader = this.$loading.show(
      Object.assign({}, this.$loaderProps, {
        'is-full-page': false,
        container: this.$refs.location,
        width: 40,
        height: 40,
        loader: 'dots',
        'enforce-focus': false,
        blur: '0'
      })
    )

    rest
      ._request(request)
      .then((response) => {
        try {
          let contactInfo = response.data.value['0']['КонтактнаяИнформация']
          for (let info of contactInfo) {
            if (info['Тип'] == 'Адрес') {
              this.location = info['Представление']
              break
            }
          }
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        loader.hide()
      })
  }
}
</script>

<style scoped lang="scss"></style>
