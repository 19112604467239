import { createApp } from 'vue'
import { createPinia } from 'pinia'

import router from './router'
import helpers from './utils/helpers'

// import VCalendar from 'v-calendar'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import { plugin, defaultConfig } from '@formkit/vue'
import { ru } from '@formkit/i18n'

import Maska from 'maska'

import { vfmPlugin } from 'vue-final-modal'

import App from './App.vue'

const app = createApp(App)

app.config.globalProperties.$loaderProps = {
  color: '#e2b155',
  width: 156,
  height: 156
}

const confFormKit = {
  locales: { ru },
  locale: 'ru',
  config: {
    classes: {
      input: 'common-input',
      label: 'common-label'
    }
  },
  messages: {
    ru: {
      validation: {
        required({ node, name }) {
          if (node.name === 'terms') {
            name = 'Условия обработки данных'
          }
          return `Обязательное поле ${name}.`
        },
        matches({ name }) {
          if (name === 'Дата рождения') {
            return `Значение должно быть в формате ДД.ММ.ГГГГ`
          } else {
            return `Недопустимый формат значения поля ${name}`
          }
        }
      },
      ui: {
        incomplete: 'Пожалуйста, исправьте ошибки'
      }
    }
  }
}

app
  .use(helpers)
  .use(createPinia())
  .use(router)
  // .use(VCalendar, {})
  .use(vfmPlugin)
  .use(VueLoading)
  .use(plugin, defaultConfig(confFormKit))
  .use(Maska)
  .mount('#app')
