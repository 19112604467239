<template>
  <div class="loan-list">
    <!-- LoanDetail -->
    <router-view></router-view>

    <div class="container" v-if="isLoansListPage">
      <div class="content">
        <h1 class="loan-list__h1 common-h1">Мои займы</h1>
      </div>
    </div>

    <div class="container">
      <div class="content">
        <div class="loan-list__cards">
          <LoanListFilterPerPage
            :filter="filter"
            :perPage="12"
            :limit="limit"
          ></LoanListFilterPerPage>
        </div>
      </div>
      <!-- content -->
    </div>
    <!-- container -->
  </div>
  <!-- loan-list -->
</template>

<script>
import LoanListFilterPerPage from '../components/LoanListFilterPerPage.vue'

export default {
  name: 'LoanList',
  props: {},
  components: {
    LoanListFilterPerPage
  },
  data() {
    return {
      limit: 4,
      filter: 'all',
    }
  },
  methods: {},
  computed: {
    isLoansListPage() {
      return this.$route.fullPath == '/loans'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.fullPath == '/loans') {
          this.limit = 0
        } else {
          this.limit = 4
        }
      }
    }
  }
}
</script>

<style lang="scss">
.loan-list {
  &__h1 {
  }

  &__filter {
  }

  &__cards {
    margin-bottom: 15px;
  }

  &__card {
  }
}
</style>
