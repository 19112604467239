<template>
    <div class="phone" v-if="phoneList">
        <a
        class="phone__a with-mini-icon-left with-mini-icon-left--phone"
        :href="$helpers.formatTel(phoneList[0].phone)"
        v-text="phoneList[0].phone"
        ></a>
        <div
        class="phone__selector"
        @mouseover="isShownPhoneList = true"
        @mouseleave="isShownPhoneList = false"
        >
        <ul class="phone__list" v-show="isShownPhoneList">
            <li
            class="phone__item"
            v-for="(phoneItem, index) in phoneList"
            :key="index"
            >
            <a class="phone__a" :href="$helpers.formatTel(phoneItem.phone)">
                <div
                class="phone__item-phone"
                v-text="phoneItem.phone"
                ></div>
                <div
                class="phone__item-name"
                v-text="phoneItem.name"
                ></div>
            </a>
            </li>
        </ul>
        </div>
    </div>
</template>


<script>
import { mapState } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'HeaderSite',
  components: {
  },
  props: {},
  data() {
    return {
      phoneList: null,
      isShownPhoneList: false
    }
  },
  methods: {
  },
  computed: {
    ...mapState(useStore, ['mainPhone'])
  },
  async created() {
    let request = {
      method: 'get',
      url: rest._constructURL(config.urls.getPhones)
    }

    await rest
      ._request(request)
      .then((response) => {
        try {
          this.phoneList = response.data.sort(function (p1, p2) {
            return p1.is_main ? -1 : 1
          })
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>


<style scoped lang="scss">
.phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;

  &__a {
    font-size: 14px;
    text-decoration: none;
    color: $color-text-black;
    white-space: nowrap;

    &:hover {
      color: $color-text-gold;
    }
  }

  &__list {
    position: absolute;
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: normal;
    transition: all 0.5s ease;
    left: 0;
    top: 100%;
    background-color: #fff;
    white-space: nowrap;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
    z-index: 100;

    /* visibility: hidden;
    opacity: 0; */

    &.is-active {
    }
  }

  &__item {
    padding: 16px 20px 16px;
    color: $color-text-black;

    &:not(:first-of-type) {
      border-top: 1px solid #f2f2f2;
    }

    &-phone {
    }

    &-name {
      font-size: 11px;
      color: $color-text-gray;
      padding-top: 6px;
    }
  }

  &__selector {
    position: relative;
    padding: 16px;
    width: 12px;
    height: 9px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 12px 9px;
    background-image: url('@/assets/i/icons/arrow_down.svg');
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .phone {
    &__a {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .phone {
    margin-left: 0;
    margin-bottom: 10px;

    &__a {
      font-weight: 500;
      font-size: 14px;
    }
  }
}
</style>