<template>
  <div>
    <div class="container">
      <div class="content">
        <h1 class="common-h1">Помощь</h1>

        <div class="faq__desc">
          Прежде чем задать новый вопрос, просим Вас ознакомиться со списком
          часто задаваемых вопросов и ответов.
          <br />
          Если ответ на Ваш вопрос опубликован в данном разделе, мы оставляем за
          собой право на него не отвечать.
        </div>

        <div class="faq" v-for="(faqCat, index) in faqCatList" :key="index">
          <div class="faq__cat">
            {{ faqCat.name }}
          </div>

          <div
            class="faq__item"
            v-for="(faq, findex) in faqCat.faqs"
            :key="findex"
          >
            <div class="faq__item-header" @click="faq.isShown = !faq.isShown">
              <div class="faq__item-title">
                {{ faq.title }}
              </div>
              <div
                class="faq__item-button common-button common-button--cored"
                :class="{ 'is-shown': faq.isShown }"
              >
                Подробнее
              </div>
            </div>
            <Transition name="fade" mode="out-in">
              <div
                v-show="faq.isShown"
                class="faq__item-text"
                v-html="faq.text"
              ></div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
    <div class="faq-form" v-if="ClientID">
      <div class="container">
        <div class="content">
          <FormKit
            v-if="!faqFormSubmited"
            type="form"
            v-model="form"
            :classes="{
              form: 'faq-form__form'
            }"
            @submit="submit"
            :actions="false"
            submit-label="Отправить"
          >
            <div class="faq-form__title">
              Если Вы не нашли ответа на интересующий Вас вопрос - напишите
              <br />
              нам, мы обязательно Вам ответим.
            </div>
            <div class="formkit-row">
              <FormKit
                type="text"
                name="name"
                label="Имя"
                :classes="{
                  input: 'common-input faq-form__input'
                }"
                validation="required"
                placeholder="Золото"
                :value="username"
              />

              <FormKit
                type="email"
                name="email"
                label="E-mail"
                validation="required|email"
                class="faq-form__input"
                :classes="{
                  input: 'faq-form__input'
                }"
                placeholder="585"
                :value="useremail"
              />

              <FormKit
                type="select"
                name="cat"
                label="Раздел вопроса"
                :classes="{
                  input: 'faq-form__select w100'
                }"
                placeholder="585"
              >
                <option value=""></option>
                <option v-for="(cat, index) in faqCatSelect" :key="index">
                  {{ cat }}
                </option>
              </FormKit>
            </div>

            <FormKit
              type="textarea"
              name="text"
              label="Текст вопроса"
              :classes="{
                outer: 'faq-form__textarea',
                input: 'common-textarea'
              }"
              rows="5"
              validation="required"
            />

            <div class="formkit-row jcsb">
              <div class="faq-form__terms">
                <FormKit
                  type="checkbox"
                  name="terms"
                  :sections-schema="{
                      // outer: { $el: null },
                      // inner: { $el: null },
                      // label: { $el: null }
                  }"
                  :classes="{
                      outer: 'formkit-checkbox'
                  }"
                  label="Я ознакомлен и согласен с условиями политики обработки персональных данных и конфиденциальности"
                  validation="required"
                  validation-visibility="dirty"
                />
              </div>

              <FormKit
                type="submit"
                label="Отправить"
                :classes="{
                  input: 'common-button common-button--gold faq-form__button'
                }"
              >
              </FormKit>
            </div> </FormKit
          ><!-- form -->
          <div v-else>
            <h2>Сообщение отправлено!</h2>
          </div>
        </div>
      </div>
    </div>
    <TermsModal></TermsModal>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'
import TermsModal from '../components/TermsModal.vue'

let rest = new RestApi()

export default {
  name: 'HelpPage',
  components: {
    TermsModal,
  },
  props: {},
  data() {
    return {
      faqFormSubmited: false,
      faqCatList: null,
      faqCatSelect: [],
      form: {
        theme: 'В личном кабинете заполнена форма на странице "Помощь"'
      },
    }
  },
  computed: {
    ...mapState(useStore, ['username', 'useremail', 'ClientID']),
    ...mapWritableState(useStore, ['isShowTerms'])
  },
  methods: {
    ...mapActions(useStore, ['EmailTemplateFAQ']),
    submit() {
      let body = this.EmailTemplateFAQ(this.form)
      const request = {
        data: {
          theme: this.form.theme,
          body
        },
        url: rest._constructURL(config.urls.sendEmail),
      }

      const loader = this.$loading.show(this.$loaderProps)

      rest
        ._request(request)
        .then((response) => {
          this.faqFormSubmited = true
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loader.hide()
        })
    },
  },
  mounted() {
    let request = {
      method: 'get',
      params: {
        format: 'json'
      },
      url: rest._constructURL(config.urls.faq)
    }

    rest
      ._request(request)
      .then((response) => {
        try {
          this.faqCatList = response.data

          for (let faqCat of this.faqCatList) {
            this.faqCatSelect.push(faqCat.name)
            faqCat.isShown = false
          }
        } catch (e) {
          console.log(e)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss">
.faq {
  &__inner {
  }

  &__desc {
    font-size: 18px;
    line-height: 24px;
    color: #828282;
    margin-bottom: 24px;
  }

  &__list {
  }

  &__cat {
    font-weight: 700;
    font-size: 24px;
    color: #e2b155;
    margin-bottom: 24px;
  }

  &__item {
    background-color: #fff;
    margin-bottom: 24px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      padding: 25px 14px 25px 20px;
    }

    &-title {
      width: 100%;
      font-weight: 600;
      font-size: 20px;
      color: #333;
    }

    &-button {
      width: 200px;
      box-sizing: border-box;
      color: #e2b155;
      white-space: nowrap;

      &.is-shown {
        &::after {
          transform: scale(-1);
        }
      }

      &::after {
        content: '';
        display: inline-block;
        position: relative;
        top: -1px;
        width: 12px;
        height: 8px;
        margin-left: 10px;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 12px 9px;
        background-image: url('@/assets/i/icons/arrow_down.svg');

        transition: all 0.5s ease;
      }
    }

    &-text {
      padding: 0 14px 25px 36px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #666;
    }
  }
}

.faq-form {
  background-color: #fff;
  padding: 46px 0 26px;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #333;
    margin-bottom: 40px;
  }

  &__input {
    width: 300px;
  }

  &__textarea {
    margin-bottom: 16px;
  }

  &__select {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
    background-size: 15px 9px;
    background-image: url('@/assets/i/icons/arrow_down.svg');
  }

  &__terms {
    display: flex;
    position: relative;
    flex-flow: column;
    margin-left: 0;
    // font-size: 12px;
    // color: #999;
    max-width: 600px;

    &-link {
      text-decoration: none;
      color: $color-text-black;
      cursor: pointer;
    }
  }

  &__send {
    width: 200px;
    box-sizing: border-box;
    padding: 13px 8px;
  }
}

@media screen and (max-width: 1000px) {
  .faq {
    &__item {
      &-title {
        font-size: 16px;
      }

      &-header {
        padding: 10px 20px;
      }
    }

  }

  .faq-form {
    &__terms {
      margin-bottom: 20px;
    }

    &__button {
      width: 180px !important;
    }

    &__input {
      width: 100%;
    }
  }

}
</style>
