<template>
  <div class="header-mobile" :class="{ active: isShowHeaderMobile }" >
      <div class="container">
        <div class="header-mobile__content">
          <div class="burger" @click="isShowHeaderMobile = true">
          </div>
          <router-link class="logo" :to="{ name: 'HomePage' }"><img src="@/assets/i/logo.svg" class="logo__image" />
          </router-link>
          <a href="https://zmed.ru" class="back-to-site with-mini-icon-left with-mini-icon-left--back"></a>
        </div>
      </div>
      <Transition appear>
        <div class="header-mobile-show" v-show="isShowHeaderMobile">
          <div class="header-mobile-show__inner">
            <div class="container">
              <div class="header-mobile-show__header">
                <div class="header-mobile-show__logo">
                  <router-link class="logo" :to="{ name: 'HomePage' }"><img src="@/assets/i/logo.svg" class="logo__image" />
                  </router-link>
                </div>
                <div class="header-mobile-show__close" @click="isShowHeaderMobile = false"></div>
                <HeaderPhone></HeaderPhone>
              </div>
              <div class="header-mobile-show__body" v-if="ClientID">
                <div
                  v-show="username"
                  class="user__link with-mini-icon-left with-mini-icon-left--perosnal"
                  v-text="username"
                ></div>
                <PaymentInfo></PaymentInfo>
              </div>
              <div class="header-mobile-show__footer">

                <HeaderMenuMiddle></HeaderMenuMiddle>
                <div @click="isShowLoginModal = true; isShowHeaderMobile = false" v-if="!ClientID"
                    class="login with-mini-icon-left with-mini-icon-left--enter">
                    Вход
                </div>
                <HeaderMenuTop></HeaderMenuTop>
  
                <router-link
                    v-if="ClientID"
                    :to="{ name: 'HomePage' }"
                    class="common-button common-button--gold"
                    @click="logout(); isShowHeaderMobile = false"
                    >Выход</router-link
                >
              </div>

            </div>
          </div>
        </div>
      </Transition>
  </div>
  <!-- header-mobile -->
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import HeaderPhone from './HeaderPhone'
import PaymentInfo from './PaymentInfo'
import HeaderMenuMiddle from './HeaderMenuMiddle'
import HeaderMenuTop from './HeaderMenuTop'

export default {
  name: 'HeaderMobile',
  components: {
    HeaderPhone,
    PaymentInfo,
    HeaderMenuMiddle,
    HeaderMenuTop,
  },
  props: {},
  data() {
    return {
      // isShowHeaderMobile: false,
    }
  },
  methods: {
    ...mapActions(useStore, ['logout'])
  },
  computed: {
    ...mapState(useStore, ['ClientID', 'username']),
    ...mapWritableState(useStore, ['isShowLoginModal', 'isShowHeaderMobile'])
  }
}
</script>

<style scoped lang="scss">
.header-mobile {
  display: none;
  padding: 20px 0 25px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

.burger {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 2px;
  background-color: #4F4F4F;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: #4F4F4F;
  }

  &::before {
    top: 8px;
  }

  &::after {
    bottom: 8px;
  }
}

@media screen and (max-width: 1000px) {
  .header-mobile {
    display: block;
  }
}

.header-mobile-show {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  
  &__inner {
    height: 100vh;
    overflow: auto;
    padding: 23px 0 40px;
    box-sizing: border-box;
  }

  &__logo {
    display: inline-block;
    margin-bottom: 30px;
  }

  &__header {
    position: relative;
    gap: 10px;
    border-bottom: 1px solid #CACACA;
    margin-bottom: 20px;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background-image: url('@/assets/i/icons/x.svg');
    background-size: contain;
  }

  &__body {
    margin-bottom: 20px;
  }

}

</style>
