<template>
  <div>
        <input
            name="birthDate"
            type="text"
            class="formkit-input common-input auth-form__input"
            v-maska="'##.##.####'"
            placeholder="ДД.ММ.ГГГГ"
            :value="props.context._value"
            @input="handleInput"
          >

  </div>
</template>

<script setup>
    const props = defineProps({
        context: Object,
    })

    function handleInput(e) {
      props.context.node.input(e.target.value)
    }
</script>
