<template>
  <Transition name="fade" mode="out-in">
    <div class="loan-detail" v-if="loan">
      <div class="container">
        <div class="content">
          <h1 class="loan-detail__h1 common-h1">
            Билет №{{ loan.LoanNumber }}
          </h1>
          <div class="loan-detail__main-info">
            <div
              class="loan-detail__main-info__item loan-detail__main-info__item--loan-sum"
            >
              <div class="loan-detail__main-info__head">
                {{ loan.LoanSum }} ₽
              </div>
              <div class="loan-detail__main-info__middle">сумма займа</div>
              <div class="loan-detail__main-info__foot">
                оформлен
                {{ $helpers.formatDate(new Date(loan.LoanDate), 'DD.MM.YYYY') }}
              </div>
            </div>

            <div
              class="loan-detail__main-info__item loan-detail__main-info__item--loan-debt"
            >
              <div class="loan-detail__main-info__head">
                {{ loan.DebtSum }} ₽
              </div>
              <div class="loan-detail__main-info__middle">
                задолженность по билету
              </div>
              <div class="loan-detail__main-info__foot">
                на сегодняшний день
              </div>
            </div>

            <div
              class="loan-detail__main-info__item loan-detail__main-info__item--loan-sum"
            >
              <div class="loan-detail__main-info__head">
                {{ loan.InterestsSum }} ₽
              </div>
              <div class="loan-detail__main-info__middle">
                процент к погашению
              </div>
              <div class="loan-detail__main-info__foot">
                на сегодняшний день
              </div>
            </div>
          </div>
        </div>
        <!-- content -->
      </div>
      <!-- container -->

      <div class="loan-detail__notice" v-if="isShowPaymentButton">
        <div class="container">
          <div class="content">
            <div class="loan-detail__notice-inner">
              <div class="loan-detail__notice-title">
                Оплатите проценты, чтобы продлить срок займа
                <div class="loan-detail__notice-subtitle">
                  Срок увеличится на 30 дней после оплаты
                </div>
              </div>
              <button
                class="loan-detail__notice-button common-button common-button--gold"
                @click="maxDateToPay ? isShowAlertModal = true : onClickRequestPayment()"
              >
                Оплатить билет банковской картой
              </button>
              <button
                class="loan-detail__notice-button common-button common-button--white"
                @click="maxDateToPay ? isShowAlertModal = true : isShowCalculateModal = true"
              >
                Оплатить проценты по QR коду
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="content">
          <div class="loan-detail__info-wrapper">
            <div class="loan-detail__info">
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">
                  Дата оформления займа
                </div>
                <div class="loan-detail__info-param-value">
                  {{
                    $helpers.formatDate(new Date(loan.LoanDate), 'DD.MM.YYYY')
                  }}
                </div>
              </div>
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">
                  Дата последней оплаты процентов
                </div>
                <div class="loan-detail__info-param-value">
                  <LastPaymentLoader :LoanID="LoanID"></LastPaymentLoader>
                </div>
              </div>
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">
                  Дата окончания займа
                </div>
                <div class="loan-detail__info-param-value">
                  {{
                    $helpers.formatDate(
                      new Date(loan.PaymentDate),
                      'DD.MM.YYYY'
                    )
                  }}
                </div>
              </div>
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">
                  Срок хранения изделия в ломбарде
                </div>
                <div class="loan-detail__info-param-value">
                  до
                  {{
                    $helpers.formatDate(
                      new Date(new Date(loan.SellingDate).getTime() - 24 * 60 * 60 * 1000),
                      'DD.MM.YYYY'
                    )
                  }}
                </div>
              </div>
              <div class="loan-detail__info-param" v-if="LocationID">
                <div class="loan-detail__info-param-name">
                  Адрес оформления займа
                </div>
                <div class="loan-detail__info-param-value">
                  <LocationLoader :LocationID="LocationID"></LocationLoader>
                </div>
              </div>
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">
                  Статус залового имущества
                </div>
                <div class="loan-detail__info-param-value">
                  {{ loan.Stuff[0].Status }}
                </div>
              </div>
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name"></div>
                <div class="loan-detail__info-param-value"></div>
              </div>
            </div>

            <div class="loan-detail__info common-text">
              <div class="loan-detail__info-param">
                <div class="loan-detail__info-param-name">Предмет залога</div>
                <div class="loan-detail__info-param-value golden-list">
                  <ul>
                    <li v-for="(stuff, index) in loan.Stuff" :key="index">
                      {{ stuff.Description }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="loan-detail__info-param loan-detail__info-param--mod">
                <div class="loan-detail__info-subparam">
                  <div class="loan-detail__info-subparam-sum">
                    {{ loan.InterestsSum }} ₽
                  </div>
                  <div class="loan-detail__info-subparam-desc">
                    процент к погашению
                  </div>
                  <div class="loan-detail__info-subparam-param">
                    0,15% в день
                  </div>
                </div>
                <div class="loan-detail__info-subparam">
                  <div class="loan-detail__info-subparam-sum">
                    {{ loan.DebtSum }} ₽
                  </div>
                  <div class="loan-detail__info-subparam-desc">
                    задолженность по билету
                  </div>
                  <div class="loan-detail__info-subparam-param">
                    Оплатить до
                    {{
                      $helpers.formatDate(
                        new Date(loan.PaymentDate),
                        'DD.MM.YYYY'
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="loan-detail__history">
              <div class="loan-detail__history-name">
                Сумма начисляемых % в день / ставка в день
              </div>
              <button
                class="loan-detail__history-button common-button common-button--gold"
                @click="isShowHistory = !isShowHistory"
                v-text="isShowHistory ? 'Скрыть' : 'Показать'"
              ></button>
            </div>
            <Transition name="fade" mode="out-in">
              <div class="history" v-show="isShowHistory">
                <div class="history__title">
                  Справка-расчет от {{ history.nowDate }} по договору “Залог от
                  {{
                    $helpers.formatDate(
                      new Date(loan.LoanDate),
                      'DD.MM.YYYY HH:mm:ss'
                    )
                  }}”
                </div>
                <div class="history__description">
                  <div class="history__description-l">
                    <span class="light gray">Залоговый билет: </span
                    ><span class="bold">{{ loan.LoanNumber }}</span>
                  </div>
                  <div class="history__description-r">
                    <span class="light gray">Дата залога: </span
                    ><span class="bold">{{
                      $helpers.formatDate(
                        new Date(loan.LoanDate),
                        'DD.MM.YYYY HH:mm'
                      )
                    }}</span>
                  </div>
                </div>
                <table class="history__table">
                  <tr>
                    <td>Дата</td>
                    <td>Долг +/-</td>
                    <td>Итог</td>
                    <td>Подробности</td>
                  </tr>
                  <tr v-for="(day, index) in history.byDays" :key="index">
                    <td>{{ day.date }}</td>
                    <td>{{ day.loanSum }}</td>
                    <td>{{ day.daySum }}</td>
                    <td>{{ day.text }}</td>
                  </tr>
                </table>
              </div>
            </Transition>
          </div>
        </div>
        <!-- content -->
      </div>
      <!-- container -->

      <vue-final-modal
        v-model="isShowCalculateModal"
        classes="modal-container"
        content-class="modal-content"
        @closed="isShowCalculateModal = isShowQR = false"
      >
        <div
          class="modal-close"
          @click="isShowCalculateModal = isShowQR = false"
        ></div>
        <div class="calculate-payment">
          <div class="calculate-payment__ticket">
            Билет №{{ calculate.LoanNumber }}
          </div>
          <div class="calculate-payment__params">
            <div class="calculate-payment__param">
              <div class="calculate-payment__param-name">Сумма займа</div>
              <div class="calculate-payment__param-value">
                {{ calculate.LoanSum }} ₽
              </div>
            </div>
            <div class="calculate-payment__param">
              <div class="calculate-payment__param-name">Общий долг</div>
              <div class="calculate-payment__param-value">
                {{ calculate.DebtSum }} ₽
              </div>
            </div>
            <div class="calculate-payment__param">
              <div class="calculate-payment__param-name">Сумма % сегодня</div>
              <div class="calculate-payment__param-value">
                {{ calculate.InterestsSum }} ₽
              </div>
            </div>
            <div class="calculate-payment__param">
              <div class="calculate-payment__param-name">
                Крайний срок оплаты
              </div>
              <div class="calculate-payment__param-value">
                {{ calculate.date }}
              </div>
            </div>
          </div>
          <div class="calculate-payment__sum">
            <div class="calculate-payment__param-name">Оплатить сейчас</div>
            <input
              type="number"
              class="common-input calculate-payment__sum-input"
              v-model="total"
            />
          </div>
          <!-- <div class="calculate-payment__email" id="calculateEmail">
            <div class="calculate-payment__param-name">Email для отправки чека</div>
            <input
              type="text"
              class="calculate-payment__email-input common-input"
              @focusout="validateEmail"
            />
          </div> -->
          <div class="calculate-payment__total">
            <div class="calculate-payment__total-name">Итого к оплате</div>
            <div class="calculate-payment__total-value">{{ total }} ₽</div>
          </div>
          <div class="calculate-payment__showqr">
            <button
              class="calculate-payment__button common-button common-button--gold"
              @click="generateQR"
            >
              Получить QR-код
            </button>
          </div>
          <Transition name="fade" mode="out-in">
            <div class="calculate-payment__hidden" v-show="isShowQR" id="QRCodeImage">
              <div class="calculate-payment__qr">
                <div class="calculate-payment__param-name">
                  Ваш QR-код для оплаты
                </div>

                <div class="calculate-payment__qr-image">
                  <a target="_blank" :href="qrImageSrc">
                    <img :src="qrImageSrc" />
                  </a>
                </div>

                <div class="calculate-payment__download">
                  <a
                    download="qr-code-lombard.png"
                    target="_blank"
                    :href="qrImageSrc"
                    class="calculate-payment__button common-button common-button--gold"
                  >
                    Скачать QR-код
                  </a>
                </div>
                <div
                  class="calculate-payment__description golden-list common-text"
                >
                  <p>
                    Вы получите квитанцию от банка сразу после оплаты.
                    <br />
                    Статус оплаты будет обновлен в личном кабинете в течение
                    суток.
                    <br />
                    Дополнительные проценты во время обработки платежа не
                    начисляются.
                  </p>
                  <p>Для оплаты воспользуйтесь одним из вариантов:</p>
                  <ul>
                    <li>
                      <b>Отсканируйте QR-код с помощью камеры</b> на своем
                      телефоне. Вы будете перенаправлены в приложение Вашего
                      банка;
                    </li>
                    <li>
                      <b>Скачайте QR-код</b> и отсканируйте его в приложении
                      Вашего банка.
                    </li>
                  </ul>
                </div>
                <div class="calculate-payment__link">
                  <a href="#" class="common-link"
                    >Полные условия оплаты и инструкция</a
                  >
                </div>
                <div class="calculate-payment__close">
                  <div
                    class="calculate-payment__button common-button common-button--gold"
                    @click="isShowCalculateModal = false"
                  >
                    Закрыть окно
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </vue-final-modal>

      <vue-final-modal
        v-model="isShowAlertModal"
        classes="modal-container"
        content-class="modal-content"
        @closed="isShowAlertModal = false"
      >
        <div
          class="modal-close"
          @click="isShowAlertModal = false"
        ></div>
        <div class="calculate-payment">
          <div class="calculate-payment__ticket">
            Билет №{{ calculate.LoanNumber }}
          </div>
          <div
            class="calculate-payment__description golden-list common-text m--alert"
          >
            <p>Срок договора займа подошел к концу. Согласно п1. ст7. Федерального закона от 19.072007 "О ломбардах".</p>
            <p>Дальнейшее пролонгирование невозможно. Просим Вас обратится в отделение для переоформления договора.</p>
          </div>
        </div>
      </vue-final-modal>
    </div>
  </Transition>
</template>

<script>
import LastPaymentLoader from '../components/LastPaymentLoader.vue'
import LocationLoader from '../components/LocationLoader.vue'
import { mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'LoanDetail',
  props: {
    LoanID: String
  },
  components: {
    LastPaymentLoader,
    LocationLoader
  },
  data() {
    return {
      calculate: {
        date: this.$helpers.formatDate(new Date(), 'DD.MM.YYYY')
      },
      location: null,
      LocationID: null,

      qrImageSrc: null,
      localhost: config.protocol + '://' + config.host,
      total: 0,
      isShowQR: false,
      isShowCalculateModal: false,
      isShowHistory: false,
      isShowAlertModal: false,
      history: {
        nowDate: this.$helpers.formatDate(new Date(), 'DD.MM.YYYY HH:mm:ss')
      },
    }
  },
  methods: {
    ...mapActions(useStore, ['getLoanStatus']),
    validateEmail(e) {
      let calculateEmail = document.getElementById('calculateEmail')
      if (this.$helpers.validateEmail(e.target.value))
        calculateEmail.classList.add('error')
      else calculateEmail.classList.remove('error')
    },
    generateQR() {
      let total = typeof this.total === 'string' ? Number(this.total.replace(',', '.')) : this.total
      total = total.toFixed(2).toString().replace('.', '')
      let QRString = rest._QRString({
        Purpose: 'Оплата по залоговому билету №' + this.calculate.LoanNumber,
        Sum: total
      })

      const request = {
        data: { string: QRString },
        url: rest._constructURL(config.urls.getQRSrc),
        responseType: 'text'
      }

      rest
        ._request(request)
        .then((response) => {
          try {
            this.qrImageSrc = this.localhost + response.data
            this.isShowQR = true
            setTimeout(() => {
              document
                .getElementById('QRCodeImage')
                .scrollIntoView({ behavior: 'smooth' })
            }, 200)
          } catch (e) {
            console.log(e)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onClickRequestPayment(e) {
      const data = {
        clientID: localStorage.ClientID, // Регистр clientID важен
        Loans: [{ LoanID: this.LoanID }]
      }
      const url = rest._constructURL(config.urls.requestPayment)
      const request = {
        data,
        url
      }

      const loader = this.$loading.show(this.$loaderProps)

      rest
        ._request(request)
        .then((response) => {
          try {
            if (response.data.paymentLink)
              window.open(response.data.paymentLink)
            else
              console.log('No paymentLink')
              console.log(response)
          } catch (e) {
            console.log(e)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .then(() => {
          loader.hide()
        })
    },
    setHistory() {
      let oneDay = 1000 * 60 * 60 * 24
      this.history.dayCount = Math.round(
        (new Date().getTime() - new Date(this.loan.LoanDate).getTime()) / oneDay
      )

      let oneDaySum = this.loan.InterestsSum / this.history.dayCount

      this.history.byDays = []
      this.history.byDays[0] = {
        date: this.$helpers.formatDate(
          new Date(this.loan.LoanDate),
          'DD.MM.YYYY HH:mm'
        ),
        loanSum: this.loan.LoanSum,
        daySum: this.loan.LoanSum,
        text: 'Начисление основного долга'
      }

      let daySum = oneDaySum.toFixed(2)
      for (let i = 1; i <= this.history.dayCount; i++) {
        let newDate = new Date(this.loan.LoanDate)
        newDate.setDate(newDate.getDate() + i)
        this.history.byDays[i] = {
          date: this.$helpers.formatDate(newDate, 'DD.MM.YYYY HH:mm'),
          loanSum: (this.loan.LoanSum + oneDaySum * i).toFixed(2),
          daySum,
          text: 'Начисление процентов основного периода займа'
        }
      }
    },
  },
  computed: {
    ...mapWritableState(useStore, ['isBlur', 'loan']),
    isShowPaymentButton() {
      if (this.loan) {
        // показываем кнопку купить, если статус "на хранении" и НЕ "в сейфе"
        if (this.getLoanStatus(this.loan) === 'active' && Number(this.loan.InterestsSum) > 0)
          return true
        else
          return false
          // return this.loan.Stuff[0].Status.toLowerCase() === 'на хранении'
          //   ? this.loan.Stuff[0].Location.toLowerCase() ===
          //     'сейф старшего товароведа'
          //     ? false
          //     : true
          //   : false
      }
    },
    maxDateToPay() {
      let months = 11;
      let date = new Date(this.loan.LoanDate);
      date.setMonth(date.getMonth() + months);
      return date < new Date();
    }
  },
  watch: {
    LoanID: {
      immediate: true,
      handler(LoanID, prevLoanID) {
        const request = {
          data: { LoanID: this.LoanID },
          url: rest._constructURL(config.urls.getLoanDetails)
        }

        const loader = this.$loading.show(this.$loaderProps)

        rest
          ._request(request)
          .then((response) => {
            try {
              if (response.data['isError']) {
                console.log(response.data['errorMessage'])
              } else {
                this.loan = response.data
              }
            } catch (e) {
              console.log(e)
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .then(() => {
            loader.hide()
          })
      }
    },
    loan: {
      handler() {
        if (this.loan) {
          this.calculate.LoanNumber = this.loan.LoanNumber
          this.calculate.LoanSum = this.loan.LoanSum
          this.calculate.DebtSum = this.loan.DebtSum
          this.calculate.InterestsSum = this.loan.InterestsSum
          this.total = Number(this.loan.InterestsSum).toFixed(2)

          if (this.loan.Stuff.length <= 0) return
          else this.LocationID = this.loan.Stuff[0].LocationID

          this.setHistory()
        }
      }
    },
    total: {
      handler() {
        this.isShowQR = false
      }
    }
  },
  mounted() {
    console.log('mounted')
  }
}
</script>

<style scoped lang="scss">
.loan-detail {
  &__h1 {
  }

  &__main-info {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 70px;

    &__item {
      width: 100%;
      padding: 32px;
      background-color: #fff;
      box-sizing: border-box;

      &--loan-sum {
      }
    }

    &__head {
      position: relative;
      padding-bottom: 16px;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 30px;
      color: $color-text-black;

      &::before {
        content: '';
        position: absolute;
        width: 34px;
        height: 1px;
        left: 0;
        bottom: 0;
        background-color: $color-gold;
      }
    }

    &__middle {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 24px;
      color: $color-text-dark;
      text-transform: lowercase;
    }

    &__foot {
      margin-bottom: 16px;
      font-weight: 300;
      font-size: 24px;
      color: $color-text-gray;
      text-transform: lowercase;
    }
  }

  &__notice {
    padding: 45px 0;
    background-color: $color-gold;
    color: #fff;
    margin-bottom: 70px;

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }

    &-title {
      font-weight: 700;
      font-size: 30px;
    }
    &-subtitle {
      margin-top: 16px;
      font-weight: 400;
      font-size: 16px;
    }

    &-button {
      padding: 16px 18px;
      background: #ffffff;
      border-radius: 25px;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
      color: $color-text-gold;
      cursor: pointer;
    }
  }

  &__calendar {
    margin-bottom: 70px;

    &-field {
      display: flex;
      gap: 85px;
    }

    &-button {
      padding: 16px 28px;
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      color: #ffffff;
      background-color: $color-gold;
      border-radius: 25px;
    }

    &-text {
      font-weight: 400;
      font-size: 18px;
      color: $color-text-black;
      margin-bottom: 13px;
    }

    &-picker {
      display: inline-block;
      position: relative;

      &-input {
        position: relative;
        background-image: url('@/assets/i/icons/calendar_check.svg');
        background-position: calc(100% - 16px) center;
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }

  &__info {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 2rem;

    &-wrapper {
      border-radius: 12px;
      padding: 24px 20px;
      background: #fff;
      margin-bottom: 70px;
    }

    &-param {
      &-name {
        margin-bottom: 6px;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        color: $color-text-gray;
      }

      &-value {
        font-weight: 400;
        font-size: 18px;
        color: $color-text-black;

        ul li {
          margin-bottom: 0;
        }
      }

      &--mod {
        grid-column: 2 / -1;
        display: flex;
        justify-content: flex-start;
        gap: 3rem;
      }
    }

    &-subparam {
      margin-top: 20px;

      &-sum {
        font-weight: 700;
        font-size: 16px;
        text-transform: uppercase;
        color: $color-text-black;
      }

      &-desc {
        font-weight: 600;
        font-size: 16px;
        color: $color-text-dark;
      }

      &-param {
        font-weight: 400;
        font-size: 16px;
        color: $color-text-gray;
      }
    }
  }

  &__history {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;

    &-name {
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      color: $color-text-gray;
    }

    &-button {
      padding: 9px;
      width: 175px;
      font-size: 14px;
    }
  }
}

.calculate-payment {
  &__ticket {
    margin-bottom: 40px;
    padding: 0 40px;
    font-weight: 600;
    font-size: 20px;
    color: $color-text-black;
    text-align: center;
  }

  &__params {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 2rem;
    margin-bottom: 24px;
  }

  &__param {
    &-name {
      font-weight: 500;
      font-size: 16px;
      color: $color-text-gray;
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    &-value {
      font-weight: 400;
      font-size: 18px;
      color: $color-text-black;
    }
  }

  &__sum {
    margin-bottom: 24px;

    &-input {
      width: 100%;
    }
  }

  &__email {
    margin-bottom: 40px;
  }

  &__email,
  &__sum {
    &-input {
      width: 100%;
      box-sizing: border-box;
    }

    &.error {
      .calculate-payment__param-name {
        color: $color-red;
      }

      input {
        border: 1px solid $color-red;
      }
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 40px;

    &-name {
      font-weight: 700;
      font-size: 20px;
      color: $color-text-gray;
      text-transform: uppercase;
    }

    &-value {
      font-weight: 600;
      font-size: 30px;
      text-align: right;
      color: $color-text-black;
    }
  }

  &__button {
  }

  &__qr {
    text-align: center;

    &-image {
      margin: 24px 0 30px;
    }
  }

  &__description {
    margin-bottom: 40px;

    &.m--alert {
      color: $color-red;
    }
  }

  &__link {
    margin-bottom: 40px;
  }

  &__showqr,
  &__download,
  &__close {
    margin-bottom: 40px;
    text-align: center;
  }
}

.history {
  &__title {
    font-weight: 600;
    margin: 20px 0 24px;
    font-size: 20px;
    color: #333;
  }

  &__description {
    display: flex;
    gap: 50%;
    margin-bottom: 40px;
  }

  &__table {
    width: 100%;
    border: 0;
    border-spacing: 0px;

    & tr {
      &:not(:first-child) {
        & td {
          padding: 16px 0;
        }
      }

      &:first-child {
        font-weight: 300;
        font-size: 16px;
        color: #bdbdbd;

        & td {
          padding: 8px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          vertical-align: top;
        }
      }

      &:nth-child(2) {
        padding-top: 20px;
      }

      & td {
        vertical-align: top;
      }
    }


  }
  & .fs16 {
    font-size: 16px;
  }
  & .fw600 {
    font-weight: 600;
  }
  & .fw400 {
    font-weight: 400;
  }
  & .up {
    text-transform: uppercase;
  }
  & .mb25 {
    margin-bottom: 25px;
  }
  & .mb5 {
    margin-bottom: 5px;
  }
  & .gray {
    color: #bdbdbd;
  }
  & .light {
    font-weight: 400;
  }
  & .bold {
    font-weight: 600;
  }
}

@media screen and (max-width: 1000px) {
  .loan-detail {
    display: block;

    &__main {
      &-info {
        padding: 0;
        margin-bottom: 40px;
        &__item {
          padding: 10px;
        }

        &__head {
          font-size: 22px;
        }

        &__middle {
          font-size: 18px;
        }

        &__foot {
          font-size: 18px;
        }
      }
    }

    &__info {
      display: block;

      &-subparam {
        margin: 0;
      }

      &-wrapper {
        padding: 14px 10px;
        margin-bottom: 40px;
      }

      &-param {
        margin-bottom: 20px;
      }
    }

    &__notice {
      padding: 20px 0;
      margin-bottom: 40px;

      &-title {
        font-size: 24px;
      }

      &-subtitle {
        margin-top: 5px;
      }

      &-inner {
        
      }
    }
  }

  .history {
    &__description {
      gap: 10px;
    }

    &__table {
      & tr:not(:first-child) {
        & td {
          padding: 8px 8px;
        }
      }
    }
  }
  
}

@media screen and (max-width: 600px) {

  .loan-detail {
    &__main {
      &-info {
        display: block;
        margin-bottom: 20px;
      }
    }
    &__notice {
      &-inner {
        flex-direction: column;
      }

      &-button {
        width: 100%;
      }
    }
  }
}
</style>
