<template>
  <div class="header">
    <div class="header-top__wrapper">
      <div class="container">
        <div class="header-top">
          <div class="header-top__left">
            <a href="https://zmed.ru" class="back-to-site with-mini-icon-left with-mini-icon-left--back">
              Вернуться на сайт
            </a>
            <HeaderPhone></HeaderPhone>
          </div>
          <div class="header-top__right">

            <HeaderMenuTop></HeaderMenuTop>

            <div
                v-show="username"
                class="user__link with-mini-icon-left with-mini-icon-left--perosnal"
                v-text="username"
            ></div>
            <router-link
                v-if="ClientID"
                :to="{ name: 'HomePage' }"
                class="exit__link with-mini-icon-left with-mini-icon-left--exit"
                @click="logout()"
                >Выход</router-link
            >
          </div>
        </div>
        <!-- header-top -->
      </div>
    </div>
    <!-- header-top__wrapper -->

    <div class="header-middle__wrapper">
      <div class="container">
        <div class="header-middle">
          <div class="header-middle__left">
            <router-link class="logo" :to="{ name: 'HomePage' }"
              ><img src="@/assets/i/logo.svg" alt="" class="logo__image"
            /></router-link>

            <PaymentInfo></PaymentInfo>
          </div>

          <HeaderMenuMiddle></HeaderMenuMiddle>
          <div @click="isShowLoginModal = true" v-if="!ClientID"
              class="login with-mini-icon-left with-mini-icon-left--enter">
              Вход
          </div>
        </div>
        <!-- header-middle -->
      </div>
    </div>
    <!-- header-middle__wrapper -->
  </div>
  <!-- header -->
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useStore } from '../stores/store'
import HeaderPhone from './HeaderPhone'
import PaymentInfo from './PaymentInfo'
import HeaderMenuMiddle from './HeaderMenuMiddle'
import HeaderMenuTop from './HeaderMenuTop'

import { RestApi } from '../services/service'
import { config } from '../settings'

let rest = new RestApi()

export default {
  name: 'HeaderSite',
  components: {
    HeaderPhone,
    PaymentInfo,
    HeaderMenuMiddle,
    HeaderMenuTop,
},
  props: {},
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(useStore, ['saveUserInfo', 'logout']),
  },
  computed: {
    ...mapState(useStore, ['ClientID', 'username']),
    ...mapWritableState(useStore, ['isShowLoginModal'])
  },
  watch: {
    ClientID: {
      handler() {
        if (!this.ClientID) return

        const data = { clientID: this.ClientID }
        const url = rest._constructURL(config.urls.getClientDetails)
        const request = {
          data,
          url
        }

        rest
          ._request(request)
          .then((response) => {
            try {
              this.saveUserInfo(response.data)
            } catch (e) {
              console.log(e)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style lang="scss">
.header {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
  background-color: #fff;

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-top-height;
    line-height: $header-top-height;

    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
    }

    &__back {
    }
  }

  &-middle {
    &__wrapper {
      border-top: 1px solid #f3f3f3;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-middle-height;
    line-height: $header-middle-height;

    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
    }
  }
}

.back-to-site {
  font-size: 14px;
  text-decoration: none;
  color: $color-text-black;
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    color: $color-text-gold;
  }
}

.login {
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-text-black;
  cursor: pointer;

  &:hover {
    color: $color-text-gold;
  }
}

.user__link {
  margin-left: 28px;
  text-decoration: none;
  color: $color-text-black;
  font-weight: 300;

  // cursor: pointer;

  // &:hover {
  //   color: $color-text-gold;
  // }
}

.exit__link {
  margin-left: 28px;
  text-decoration: none;
  color: $color-text-black;

  &:hover {
    color: $color-text-gold;
  }
}

.logo {
  display: block;
  line-height: normal;

  &__image {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .back-to-site {
    font-size: 10px;
  }
}

@media screen and (max-width: 1000px) {
  .header {
    display: none;
  }

  .logo {
    width: 142px;
  }

  .user__link {
    margin-left: 0;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .login {
    margin-bottom: 20px;
  }

}
</style>
