<template>
  <div class="loyalty-program">
    <div class="container">
      <div class="content">
        <h1 class="loyalty-program__h1 common-h1">Программа лояльности</h1>
        <div class="loyalty-program__description common-text">
          <p>
            Участвуйте в программе лояльности сети ломбардов “Золотые Медведи”!
            Оплачивайте проценты по залоговым билетам. При достижении
            определенной суммы Ваш статус повысится, а вместе с ним - повысится
            стоимость оценки изделий и снизится процентная ставка в день!
          </p>
        </div>
        <div class="loyalty-program__status-block">
          <div class="loyalty-program__status-title">Статус клиента</div>
          <div class="loyalty-program__table-wrapper">
            <table class="loyalty-program__table">
              <tr class="loyalty-program__table-tr active">
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title mb25">
                    Постоянный
                  </div>
                  <div class="loyalty-table-text-common">от 5000 до 10000 ₽</div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">Ставка</div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    0,15% в день
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Увелечение оценки
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    +5%
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Вы здесь!
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    оплачено: 3200 ₽
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
              </tr>
              <tr class="loyalty-program__table-tr">
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title mb25">Лояльный</div>
                  <div class="loyalty-table-text-common">от 10000 до 20000 ₽</div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">Ставка</div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    0,15% в день
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Увелечение оценки
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    +5%
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td"></td>
              </tr>
              <tr class="loyalty-program__table-tr">
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title mb25">Особый</div>
                  <div class="loyalty-table-text-common">о 20000 до 40000 ₽</div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">Ставка</div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    0,15% в день
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Увелечение оценки
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    +5%
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td"></td>
              </tr>
              <tr class="loyalty-program__table-tr">
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title mb25">VIP</div>
                  <div class="loyalty-table-text-common">
                    от 40000 до 400000 ₽
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">Ставка</div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    0,15% в день
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Увелечение оценки
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    +5%
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td"></td>
              </tr>
              <tr class="loyalty-program__table-tr">
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title mb25">
                    Супер VIP
                  </div>
                  <div class="loyalty-table-text-common">
                    от 400000 до 800000 ₽
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">Ставка</div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    0,15% в день
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td">
                  <div class="loyalty-table-text-common title up mb5">
                    Увелечение оценки
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 mb5 fw600"
                  >
                    +5%
                  </div>
                  <div
                    class="loyalty-table-text-common active-white gray fs16 fw400"
                  >
                    4,5% в месяц
                  </div>
                </td>
                <td class="loyalty-program__table-td"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoyaltyProgram',
  props: {},
  data() {
    return {
      loyalty: {
        status: 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loyalty-program {
  &__status {
    &-title {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  &__table {
    width: 100%;

    &-wrapper {
      overflow: auto;
      white-space: nowrap;
    }

    width: 100%;
    border-collapse: separate;
    border-spacing: 0 24px;

    & tr.active {
      background-color: $color-gold;

      & .active-white {
        color: #fff;
      }
    }

    & td {
      padding: 20px;

      &:first-child {
        border-radius: 12px 0 0 12px;
      }
      &:last-child {
        border-radius: 0 12px 12px 0;
      }
    }
  }
}

.loyalty-table-text-common {
  font-weight: 500;
  font-size: 18px;
  color: $color-text-black;

  &.title {
    font-size: 20px;
    font-weight: 600;
  }
  &.fs16 {
    font-size: 16px;
  }
  &.fw600 {
    font-weight: 600;
  }
  &.fw400 {
    font-weight: 400;
  }
  &.up {
    text-transform: uppercase;
  }
  &.mb25 {
    margin-bottom: 25px;
  }
  &.mb5 {
    margin-bottom: 5px;
  }
  &.gray {
    color: $color-text-dark;
  }
}
</style>
